<!--
 * @Author: Louis
 * @Date: 2019-08-13 10:33:18
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-27 16:27:40
 * @Description: 商旅管理-机票预订-查询入口界面
 -->
<template>
  <div class="travel-flight">
    <en-carousel :pic-list="photoList">
      <div class="flight-form">
        <div class="flight-form-content">
          <div class="flight-form-header">
            <div
              class="flight-header-item"
              :class="{'active':isRound === 1}"
              @click="isRound = 1"
            >
              单程
            </div>
            <div
              class="flight-header-item"
              :class="{'active':isRound === 2}"
              @click="isRound = 2"
            >
              往返
            </div>
          </div>
          <el-form
            ref="form"
            class="flight-form-container"
            :model="flightForm"
            :rules="flightRules"
          >
            <div class="city-change-area">
              <en-icon
                name="chengshiqiehuan"
                size="18px"
                @click.native="changeCityInfo"
              >
              </en-icon>
            </div>
            <el-form-item prop="fromCity">
              <en-city
                v-model="flightForm.fromCity"
                tips-label="出发城市"
                placeholder="请选择出发城市"
                type="flight"
              >
              </en-city>
            </el-form-item>
            <el-form-item prop="arrivalCity">
              <en-city
                v-model="flightForm.arrivalCity"
                tips-label="到达城市"
                placeholder="请选择到达城市"
                type="flight"
              >
              </en-city>
            </el-form-item>
            <el-form-item prop="fromDate">
              <div class="tips-label">
                去程日期
              </div>
              <el-date-picker
                v-model="flightForm.fromDate"
                type="date"
                prefix-icon="el-icon-xxx"
                placeholder="请选择去程日期"
                format="yyyy - MM - dd"
                value-format="yyyy-MM-dd"
                :picker-options="fromDateOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              v-if="isRound === 2"
              prop="arrivalDate"
            >
              <div class="tips-label">
                返程日期
              </div>
              <el-date-picker
                v-model="flightForm.arrivalDate"
                type="date"
                prefix-icon="el-icon-xxx"
                placeholder="请选择返程日期"
                format="yyyy - MM - dd"
                value-format="yyyy-MM-dd"
                :picker-options="arrivalDateOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="level">
              <div class="tips-label">
                舱位等级
              </div>
              <el-select
                v-model="flightForm.level"
                placeholder="请选择舱位等级"
              >
                <el-option
                  v-for="(item, index) in levelList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                  {{ item.name }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <en-button @click="searchFlight">
            立即搜索
          </en-button>
        </div>
      </div>
    </en-carousel>
  </div>
</template>
<script>
import { isEmpty, cloneDeep } from "lodash";
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";
import EnCarousel from "../components/en-carousel";
import EnCity from "../components/en-city";

export default {
  name: "FlightTravel",
  components: {
    EnCarousel,
    EnCity
  },
  data() {
    const validateFromCity = (rule, value, callback) => {
      if (isEmpty(value)) {
        return callback(new Error("请选择出发城市"));
      }
      if (this.flightForm.arrivalCity.cityCode === this.flightForm.fromCity.cityCode) {
        return callback(new Error("出发城市和达到城市不能相同"));
      }
      return callback();
    };
    const validateArrivalCity = (rule, value, callback) => {
      if (isEmpty(value)) {
        return callback(new Error("请选择到达城市"));
      }
      if (this.flightForm.arrivalCity.cityCode === this.flightForm.fromCity.cityCode) {
        return callback(new Error("出发城市和达到城市不能相同"));
      }
      return callback();
    };
    const validateArrivalDate = (rule, value, callback) => {
      if (this.isRound === 2 && value === "") {
        return callback(new Error("请选择返程日期"));
      }
      return callback();
    };
    return {
      flightForm: {
        fromCity: {},
        arrivalCity: {},
        fromDate: "",
        arrivalDate: "",
        level: -1
      },
      flightRules: {
        fromCity: [
          { validator: validateFromCity, trigger: "change" }
        ],
        arrivalCity: [
          { validator: validateArrivalCity, trigger: "change" }
        ],
        fromDate: [
          {
            required: true,
            message: "请选择去程日期",
            trigger: true
          }
        ],
        arrivalDate: [
          { validator: validateArrivalDate, trigger: "blur" }
        ],
        level: [
          {
            required: true,
            message: "请选择舱位等级",
            trigger: true
          }
        ]
      },
      photoList: [
        "https://images.softfuxi.com/static/user_flight-bg-1.jpg",
        "https://images.softfuxi.com/static/user_flight-bg-2.jpg",
        "https://images.softfuxi.com/static/user_flight-bg-3.jpg"
      ],
      isRound: 1,
      levelList: [
        {
          id: -1,
          name: "不限"
        },
        {
          id: 1,
          name: "经济舱"
        },
        {
          id: 2,
          name: "头等舱"
        }
      ],
      fromCityShow: false,
      arrivalCityShow: false
    };
  },
  computed: {
    ...mapGetters("businessTravel", [
      "flightType",
      "flightInfo"
    ]),
    fromDateOptions() {
      const r = {};
      r.disabledDate = (time) => {
        let maxFlag = false;
        let minFlag = false;
        minFlag = time.getTime() < dayjs().subtract(1, "day").valueOf();
        if (this.isRound === 2 && this.flightForm.arrivalDate) {
          maxFlag = time.getTime() > dayjs(this.flightForm.arrivalDate).valueOf();
        }
        return minFlag || maxFlag;
      };
      return r;
    },
    arrivalDateOptions() {
      const r = {};
      r.disabledDate = (time) => {
        let minFlag = false;
        minFlag = time.getTime() < dayjs().subtract(1, "day");
        if (this.flightForm.fromDate) {
          minFlag = time.getTime() < dayjs(this.flightForm.fromDate).valueOf();
        }
        return minFlag;
      };
      return r;
    }
  },
  methods: {
    ...mapActions("businessTravel", [
      "setFlightInfo",
      "setFlightType"
    ]),
    /**
     * @description 更改城市
     */
    changeCityInfo() {
      const { fromCity } = this.flightForm;
      const { arrivalCity } = this.flightForm;
      this.flightForm.arrivalCity = fromCity;
      this.flightForm.fromCity = arrivalCity;
    },
    /**
     * @description 搜索航班
     */
    searchFlight() {
      let message = null;
      if (!this.flightForm.level) {
        message = "请选择舱位等级";
      }
      if (!this.flightForm.fromDate) {
        message = "请选择去程日期";
      }
      if (this.isRound === 2 && !this.flightForm.arrivalDate) {
        message = "请选择返程日期";
      }
      if (!this.flightForm.arrivalCity.cityCode) {
        message = "请选择到达城市";
      }
      if (!this.flightForm.fromCity.cityCode) {
        message = "请选择出发城市";
      }
      if (this.flightForm.arrivalCity.cityCode === this.flightForm.fromCity.cityCode) {
        message = "出发城市和达到城市不能相同";
      }
      if (this.flightForm.arrivalCity.cityCode === this.flightForm.fromCity.cityCode) {
        message = "出发城市和达到城市不能相同";
      }
      if (message) {
        this.$message({
          message,
          type: "warning"
        });
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.setFlightType(this.isRound);
          this.setFlightInfo(this.flightForm);
          this.$router.push({
            path: "/business-travel/flight/list",
            query: {
              tripId: this.$route.query.tripId
            }
          });
        }
      });
    }
  },
  beforeMount() {
    this.isRound = this.flightType || 1;
    if (!isEmpty(this.flightInfo)) {
      this.flightForm = cloneDeep(this.flightInfo);
    }
    this.flightForm.fromDate = dayjs().format("YYYY-MM-DD");
    this.flightForm.arrivalDate = dayjs().add(1, "day").format("YYYY-MM-DD");
  }
};
</script>
<style lang="scss" scoped>
  .travel-flight /deep/ {
    position: relative;

    .flight-form {
      position: absolute;
      right: 42px;
      top: 42px;
      width: 520px;
      background-color: rgb(60, 128, 197);
      border-radius: 4px 4px 4px;
      padding: 8px 8px 8px;
      z-index: 3;

      .flight-form-content {
        height: 100%;
        background-color: #ffffff;
        border-radius: 2px 2px 2px;
        padding: 20px 20px 20px;

        .flight-form-header {
          font-size: 14px;
          line-height: 14px;
          display: flex;
          justify-content: space-between;
          padding: 0 67px;
          border-bottom: 1px solid #e8ecf2;

          .flight-header-item {
            width: 120px;
            text-align: center;
            border-bottom: 2px solid #ffffff;
            padding-bottom: 13px;
            color: #333333;
            font-weight: 400;
            cursor: pointer;

            &.active {
              border-color: #3e90fe;
              color: #3e90fe;
              margin-bottom: -1px;
              padding-bottom: 14px;
            }
          }
        }

        .el-form {
          padding: 20px 0 0 20px;
          width: 444px;
          position: relative;

          .el-form-item {
            margin-bottom: 16px !important;

            &:nth-last-child(1) {
              margin-bottom: 20px !important;
            }

            .el-form-item__content {
              position: relative;

              .tips-label {
                position: absolute;
                left: 12px;
                line-height: 12px;
                top: 13px;
                z-index: 1;
                font-weight: 400;
                color: #91a1b7;
              }

              .el-input {
                line-height: 36px;
                height: 36px;
                width: 100%;

                input {
                  height: 36px;
                  padding: 0 72px;
                  color: #333333;
                }
              }

              .el-select {
                width: 100%;
                line-height: 36px;
                height: 36px;
              }
            }
          }

          .city-change-area {
            position: absolute;
            top: 36px;
            right: -20px;
            width: 18px;
            height: 56px;
            border-radius: 0 6px 6px 0;
            border: 1px solid #e8ecf2;
            border-left: none;

            .en-icon {
              color: #a9b5c6;
              position: absolute;
              top: 17px;
              right: -10px;
              cursor: pointer;
            }
          }
        }

        .el-button {
          width: 426px;
          font-size: 16px;
          padding: 10px 0;
          margin: 0 19px;
        }
      }
    }
  }
</style>
<style lang="scss">
  .el-date-picker__header-label{
    font-size: 14px!important;
  }
</style>
