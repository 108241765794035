<!--
 * @Author: Louis
 * @Date: 2019-08-16 15:53:03
 * @LastEditors: Louis
 * @LastEditTime: 2019-08-16 17:25:29
 * @Description: 多选下拉选择器
 -->
<template>
  <div
    v-click-outside="closeDialogInfo"
    class="en-multi-select"
    @mouseenter="open = true"
    @mouseleave="open = false"
  >
    <div
      class="en-multi-select-label"
      :class="{'active':open}"
    >
      {{ label }}
      <en-icon
        name="zhankai"
        size="10px"
      ></en-icon>
    </div>
    <div
      v-if="open"
      class="arrow"
    ></div>
    <div
      v-if="open"
      class="en-multi-select-container"
    >
      <div
        v-for="item in dataList"
        :key="item.name"
        class="en-multi-select-item"
        :class="{'active':item.selected === true}"
        @click="selectData(item)"
      >
        <div class="label">
          {{ item[labelKey] }}
        </div>
        <en-icon
          name="yixuan"
          size="10px"
        ></en-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnMultiSelect",
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    withLabel: {
      type: Boolean,
      default: true
    },
    labelKey: {
      type: String,
      default: "name"
    }
  },
  data() {
    return {
      open: false,
      dataList: []
    };
  },
  watch: {
    value() {
      this.updateDataList();
    },
    list() {
      this.setDataInfo();
    }
  },
  mounted() {
    this.setDataInfo();
  },
  methods: {
    /**
     * @description 更新数据信息
     */
    updateDataList() {
      this.dataList.forEach((item) => {
        let flag = false;
        this.value.forEach((cItem) => {
          if (item.id === cItem.id) {
            flag = true;
          }
        });
        item.selected = flag;
      });
      this.dataList.splice(this.dataList.length, 1);
    },
    /**
     * @description 设置数据
     */
    setDataInfo() {
      this.list.forEach((item, index) => {
        if (!this.withLabel || this.withLabel === "false") {
          this.list[index] = {
            selected: false,
            name: item
          };
        } else {
          item.selected = false;
        }
      });
      this.dataList = JSON.parse(JSON.stringify(this.list));
    },
    /**
     * @description 选择数据
     * @param itemData
     * @param itemIndex
     */
    selectData(itemData) {
      console.log("selectData", itemData);
      itemData.selected = !itemData.selected;
      this.dataFormat();
    },
    /**
     * @description 数据格式化
     */
    dataFormat() {
      const list = [];
      this.dataList.forEach((item) => {
        if (item.selected) {
          list.push(!this.withLabel || this.withLabel === "false" ? item.name : item);
        }
      });
      this.$emit("input", list);
    },
    /**
     * @description 关闭弹窗
     */
    closeDialogInfo() {
      this.open = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .en-multi-select {
    user-select: none;
    position: relative;

    .en-multi-select-label {
      line-height: 1;
      font-size: 12px;
      color: #333333;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      padding: 18px 0;

      .en-icon {
        transition: 0.3s ease-in-out all;
        color: #a9b5c6;
        margin-left: 10px;
      }

      &.active {
        color: #3e90fe;

        .en-icon {
          color: #3e90fe;
          transform: rotate(-180deg);
        }
      }
    }

    .arrow {
      width: 0;
      height: 0;
      position: absolute;
      left: 30px;
      z-index: 5;
      top: 35px;
      border-color: transparent;
      border-bottom-color: rgba(232, 236, 242, 1);
      border-style: solid;
      border-width: 6px;
      border-top-width: 0;

      &::after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-bottom-color: #fff;
        content: " ";
        border-width: 5px;
        top: -4px;
        left: -5px;
      }
    }

    .en-multi-select-container {
      position: absolute;
      width: 192px;
      max-height: 400px;
      overflow-y: auto;
      background-color: #ffffff;
      top: 40px;
      left: 0;
      z-index: 3;
      border: 1px solid rgba(232, 236, 242, 1);
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.04);
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;

      .en-multi-select-item {
        font-size: 12px;
        color: #636c78;
        padding: 12px 12px 12px;
        display: flex;
        position: relative;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
          background-color: #f5f8fc;
        }

        .en-icon {
          color: #3e90fe;
          display: none;
        }

        &.active {
          color: #3e90fe;

          .en-icon {
            display: block;
          }
        }
      }
    }
  }
</style>
