var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flight-order" },
    [
      _c("div", { staticClass: "flight-order-container" }, [
        _c(
          "div",
          { staticClass: "flight-order-header" },
          [
            _c("en-icon", {
              staticClass: "icon-back",
              attrs: {
                name: "fanhuishangcengji",
                size: "18px",
                color: "#A9B5C6",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.backToList.apply(null, arguments)
                },
              },
            }),
            _c("div", { staticClass: "label" }, [
              _vm._v(" " + _vm._s(_vm.flightInfo.fromCity.enCityName) + " "),
            ]),
            _c("en-icon", {
              staticClass: "icon-qiehuan",
              attrs: { name: "qiehuan", size: "16px" },
            }),
            _c("div", { staticClass: "label" }, [
              _vm._v(" " + _vm._s(_vm.flightInfo.arrivalCity.enCityName) + " "),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "en-loading",
                rawName: "v-en-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "flight-order-list",
          },
          [
            _c(
              "div",
              {
                staticClass: "flight-order-item",
                class: { isRound: _vm.flightType === 2 },
              },
              [
                _vm.flightType === 2
                  ? _c(
                      "div",
                      { staticClass: "flight-list-item-status is-round" },
                      [_vm._v(" 去 ")]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "flight-list-item-basic" }, [
                  _c("div", {
                    staticClass: "icon_airline",
                    class: ["Airlines_" + _vm.singleData.carrier],
                  }),
                  _c("div", { staticClass: "flight-name-info" }, [
                    _c("div", { staticClass: "flight-list-item-name" }, [
                      _vm._v(" " + _vm._s(_vm.singleData.carrierName) + " "),
                    ]),
                    _c("div", { staticClass: "flight-list-item-type" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.singleData.flightNo +
                              " 空客" +
                              _vm.singleData.planeType +
                              " " +
                              "（" +
                              _vm.singleData.airPlaneSizeCh +
                              "）"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "flight-list-item-time" }, [
                  _c("div", { staticClass: "flight-list-time-item" }, [
                    _c("div", { staticClass: "time" }, [
                      _vm._v(
                        " " + _vm._s(_vm.singleData.takeoffTimeShow) + " "
                      ),
                    ]),
                    _c("div", { staticClass: "air-port" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.singleData.depAirportCh +
                              _vm.singleData.fromTerminal
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "flight-list-time-line left" }),
                  _c("div", { staticClass: "flight-list-time-used" }, [
                    _c("div", { staticClass: "time" }, [
                      _vm._v(" " + _vm._s(_vm.singleData.useTime) + " "),
                    ]),
                    _vm.singleData.stopAirport
                      ? _c("div", { staticClass: "flight-list-time-tip" }, [
                          _c("span", [_vm._v("停")]),
                          _vm._v(" " + _vm._s(_vm.singleData.stopCityCh) + " "),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "flight-list-time-line right" }),
                  _c("div", { staticClass: "flight-list-time-item" }, [
                    _c("div", { staticClass: "time" }, [
                      _vm._v(" " + _vm._s(_vm.singleData.arriveTimeShow) + " "),
                    ]),
                    _c("div", { staticClass: "air-port" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.singleData.arrAirportCh +
                              _vm.singleData.arrTerminal
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "flight-list-item-price" }, [
                  _c("div", { staticClass: "price" }, [
                    _c("span", { staticClass: "unit" }, [_vm._v("￥")]),
                    _vm._v(
                      _vm._s(_vm._f("$thousandFormat")(_vm.singleData.price)) +
                        " "
                    ),
                  ]),
                ]),
              ]
            ),
            _vm.flightType === 2
              ? _c(
                  "div",
                  {
                    staticClass: "flight-order-item",
                    class: { isRound: _vm.flightType === 2 },
                  },
                  [
                    _c("div", { staticClass: "flight-list-item-status" }, [
                      _vm._v(" 返 "),
                    ]),
                    _c("div", { staticClass: "flight-list-item-basic" }, [
                      _c("div", {
                        staticClass: "icon_airline",
                        class: ["Airlines_" + _vm.roundData.carrier],
                      }),
                      _c("div", { staticClass: "flight-name-info" }, [
                        _c("div", { staticClass: "flight-list-item-name" }, [
                          _vm._v(" " + _vm._s(_vm.roundData.carrierName) + " "),
                        ]),
                        _c("div", { staticClass: "flight-list-item-type" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.roundData.flightNo +
                                  " 空客" +
                                  _vm.roundData.planeType +
                                  " " +
                                  "（" +
                                  _vm.roundData.airPlaneSizeCh +
                                  "）"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "flight-list-item-time" }, [
                      _c("div", { staticClass: "flight-list-time-item" }, [
                        _c("div", { staticClass: "time" }, [
                          _vm._v(
                            " " + _vm._s(_vm.roundData.takeoffTimeShow) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "air-port" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.roundData.depAirportCh +
                                  _vm.roundData.fromTerminal
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "flight-list-time-line left" }),
                      _c("div", { staticClass: "flight-list-time-used" }, [
                        _c("div", { staticClass: "time" }, [
                          _vm._v(" " + _vm._s(_vm.roundData.useTime) + " "),
                        ]),
                        _vm.roundData.stopAirport
                          ? _c("div", { staticClass: "flight-list-time-tip" }, [
                              _c("span", [_vm._v("停")]),
                              _vm._v(
                                " " + _vm._s(_vm.roundData.stopCityCh) + " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "flight-list-time-line right" }),
                      _c("div", { staticClass: "flight-list-time-item" }, [
                        _c("div", { staticClass: "time" }, [
                          _vm._v(
                            " " + _vm._s(_vm.roundData.arriveTimeShow) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "air-port" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.roundData.arrAirportCh +
                                  _vm.roundData.arrTerminal
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "flight-list-item-price" }, [
                      _c("div", { staticClass: "price" }, [
                        _c("span", { staticClass: "unit" }, [_vm._v("￥")]),
                        _vm._v(
                          _vm._s(
                            _vm._f("$thousandFormat")(_vm.roundData.price)
                          ) + " "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "flight-order-details",
            class: { open: _vm.showRules },
          },
          [
            _c(
              "div",
              { staticClass: "flight-order-details-head" },
              [
                _c(
                  "div",
                  {
                    staticClass: "rules-toggle",
                    on: { click: _vm.showRulesInfo },
                  },
                  [
                    _vm._v(" 退改规则 "),
                    _c("en-icon", {
                      class: { open: _vm.showRules },
                      attrs: { name: "zhankai" },
                    }),
                  ],
                  1
                ),
                _c("el-tooltip", [
                  _c("div", { staticClass: "total-price" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(" 订单总额： "),
                    ]),
                    _c("div", { staticClass: "unit" }, [_vm._v(" ￥ ")]),
                    _c("div", { staticClass: "price" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("$thousandFormat")(_vm.totalPrice)) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "tooltip-content",
                      attrs: { slot: "content" },
                      slot: "content",
                    },
                    [
                      _c("div", { staticClass: "content-item" }, [
                        _c("div", { staticClass: "price-item" }, [
                          _vm._v(
                            " 去程：成人票价 ￥" +
                              _vm._s(_vm.singleData.price) +
                              " * " +
                              _vm._s(_vm.personalList.length) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "price-item" }, [
                          _vm._v(
                            " 机建燃油 ￥" +
                              _vm._s(
                                _vm.singleData.ticketInfo.fee +
                                  _vm.singleData.ticketInfo.tax
                              ) +
                              " * " +
                              _vm._s(_vm.personalList.length) +
                              " "
                          ),
                        ]),
                      ]),
                      _vm.flightType === 2
                        ? _c("div", { staticClass: "content-item" }, [
                            _c("div", { staticClass: "price-item" }, [
                              _vm._v(
                                " 返程：成人票价 ￥" +
                                  _vm._s(_vm.roundData.price) +
                                  " * " +
                                  _vm._s(_vm.personalList.length) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "price-item" }, [
                              _vm._v(
                                " 机建燃油 ￥" +
                                  _vm._s(
                                    _vm.roundData.ticketInfo.fee +
                                      _vm.roundData.ticketInfo.tax
                                  ) +
                                  " * " +
                                  _vm._s(_vm.personalList.length) +
                                  " "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "flight-order-details-list",
                class: { show: _vm.showRules },
              },
              [
                _vm.flightType === 2
                  ? _c("en-tabs", {
                      attrs: { type: "white", list: _vm.roundList },
                      on: { change: _vm.onTabsChange },
                      model: {
                        value: _vm.activeRound,
                        callback: function ($$v) {
                          _vm.activeRound = $$v
                        },
                        expression: "activeRound",
                      },
                    })
                  : _vm._e(),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData, stripe: "", border: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "类型", prop: "type" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.tableHeader[0],
                        prop: "beforeFlight",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: _vm.tableHeader[1], prop: "afterFlight" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "flight-personal-container",
          class: { open: _vm.showRules },
        },
        [
          _c("en-title-card", {
            attrs: { name: "乘机人信息" },
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c("en-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.personalList.length < 3 && !_vm.rebooking,
                          expression: "personalList.length < 3 && !rebooking",
                        },
                      ],
                      staticClass: "icon-add",
                      attrs: {
                        name: "icontianjia-danchuang",
                        size: "16",
                        color: "#75AADC",
                      },
                      on: {
                        handleClick: function ($event) {
                          _vm.contactShow = true
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "en-loading",
                  rawName: "v-en-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "flight-personal-list",
            },
            _vm._l(_vm.personalList, function (item, index) {
              return _c(
                "div",
                { key: item.no, staticClass: "flight-personal-item" },
                [
                  _c("div", { staticClass: "flight-personal-head" }, [
                    _vm._v(" " + _vm._s(item.name.substring(0, 1)) + " "),
                  ]),
                  _c("div", { staticClass: "flight-personal-detail" }, [
                    _c("div", { staticClass: "flight-personal-name" }, [
                      _vm._v(" " + _vm._s(item.name) + " "),
                    ]),
                    _c("div", { staticClass: "flight-personal-no" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("hideCardNo")(item.idNumber, item.idType)
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  !_vm.rebooking
                    ? _c("en-icon", {
                        attrs: { name: "shanchu-liebiao", size: "small" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.modifyPersonal(index)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "flight-button-order" },
            [
              _c(
                "en-button",
                {
                  staticClass: "flight-button__order",
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(_vm._s(_vm.rebooking ? "重新预定" : "预订"))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.contactShow
        ? _c("en-contact", {
            attrs: { "dialog-visible": _vm.contactShow },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.contactShow = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.contactShow = $event
              },
              getEnContactConfirm: _vm.getEnContactConfirm,
            },
          })
        : _vm._e(),
      _c(
        "en-dialog",
        {
          attrs: {
            visible: _vm.sourceExcessiveDialog,
            width: "700px",
            title: "资源超标",
          },
          on: {
            close: function ($event) {
              _vm.sourceExcessiveDialog = false
            },
          },
        },
        [
          _c(
            "div",
            {},
            [
              _c("el-input", {
                staticClass: "source-excessive",
                attrs: {
                  type: "textarea",
                  placeholder: "请输入100字以内超标事由",
                  resize: "none",
                  maxlength: "100",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.sourceExcessiveValue,
                  callback: function ($$v) {
                    _vm.sourceExcessiveValue = $$v
                  },
                  expression: "sourceExcessiveValue",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("en-button", { on: { click: _vm.onSourceExcessive } }, [
                _vm._v(" 确定 "),
              ]),
            ],
            1
          ),
        ]
      ),
      _c("en-itinerary", {
        attrs: {
          "dialog-visible": _vm.completeDialog,
          "is-new-node": _vm.tripId ? false : true,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.completeDialog = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.completeDialog = $event
          },
          selectTripData: _vm.onSelectTrip,
          selectCreateNewTrip: _vm.onNewTrip,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }