<!--
 * @Author: Louis
 * @Date: 2019-08-15 11:50:46
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-25 16:02:55
 * @Description: 商旅管理-机票预订-订单界面
 -->
<template>
  <div class="flight-order">
    <div class="flight-order-container">
      <div class="flight-order-header">
        <en-icon
          name="fanhuishangcengji"
          class="icon-back"
          size="18px"
          color="#A9B5C6"
          @click.native="backToList"
        >
        </en-icon>
        <div class="label">
          {{ flightInfo.fromCity.enCityName }}
        </div>
        <en-icon
          name="qiehuan"
          class="icon-qiehuan"
          size="16px"
        >
        </en-icon>
        <div class="label">
          {{ flightInfo.arrivalCity.enCityName }}
        </div>
      </div>
      <div
        v-en-loading="loading"
        class="flight-order-list"
      >
        <div class="flight-order-item" :class="{'isRound': flightType === 2}">
          <div class="flight-list-item-status is-round" v-if="flightType === 2">
            去
          </div>
          <div class="flight-list-item-basic">
            <div class="icon_airline" :class="['Airlines_' + singleData.carrier]"></div>
<!--            <en-icon-->
<!--              :name="Math.random() > 0.5 ? 'chongqinghangkong' : 'donghaihangkong'"></en-icon>-->
            <div class="flight-name-info">
              <div class="flight-list-item-name">
                {{ singleData.carrierName }}
              </div>
              <div class="flight-list-item-type">
                {{ singleData.flightNo + " 空客" + singleData.planeType + " " + "（" +
                singleData.airPlaneSizeCh + "）" }}
              </div>
            </div>
          </div>
          <div class="flight-list-item-time">
            <div class="flight-list-time-item">
              <div class="time">
                {{ singleData.takeoffTimeShow }}
              </div>
              <div class="air-port">
                {{ singleData.depAirportCh + singleData.fromTerminal }}
              </div>
            </div>
            <div class="flight-list-time-line left"></div>
            <div class="flight-list-time-used">
              <div class="time">
                {{ singleData.useTime }}
              </div>
              <div class="flight-list-time-tip" v-if="singleData.stopAirport">
                <span>停</span> {{singleData.stopCityCh}}
              </div>
            </div>
            <div class="flight-list-time-line right"></div>
            <div class="flight-list-time-item">
              <div class="time">
                {{ singleData.arriveTimeShow }}
              </div>
              <div class="air-port">
                {{ singleData.arrAirportCh + singleData.arrTerminal }}
              </div>
            </div>
          </div>
          <div class="flight-list-item-price">
            <div class="price">
              <span class="unit">￥</span>{{ singleData.price | $thousandFormat}}
            </div>
          </div>
        </div>
        <div class="flight-order-item" :class="{'isRound': flightType === 2}"
             v-if="flightType === 2">
          <div class="flight-list-item-status">
            返
          </div>
          <div class="flight-list-item-basic">
            <div class="icon_airline" :class="['Airlines_' + roundData.carrier]"></div>
<!--            <en-icon-->
<!--              :name="Math.random() > 0.5 ? '-chongqinghangkong' : '-donghaihangkong'"></en-icon>-->
            <div class="flight-name-info">
              <div class="flight-list-item-name">
                {{ roundData.carrierName }}
              </div>
              <div class="flight-list-item-type">
                {{ roundData.flightNo + " 空客" + roundData.planeType + " " + "（" +
                roundData.airPlaneSizeCh + "）" }}
              </div>
            </div>
          </div>
          <div class="flight-list-item-time">
            <div class="flight-list-time-item">
              <div class="time">
                {{ roundData.takeoffTimeShow }}
              </div>
              <div class="air-port">
                {{ roundData.depAirportCh + roundData.fromTerminal }}
              </div>
            </div>
            <div class="flight-list-time-line left"></div>
            <div class="flight-list-time-used">
              <div class="time">
                {{ roundData.useTime }}
              </div>
              <div class="flight-list-time-tip" v-if="roundData.stopAirport">
                <span>停</span> {{roundData.stopCityCh}}
              </div>
            </div>
            <div class="flight-list-time-line right"></div>
            <div class="flight-list-time-item">
              <div class="time">
                {{ roundData.arriveTimeShow }}
              </div>
              <div class="air-port">
                {{ roundData.arrAirportCh + roundData.arrTerminal }}
              </div>
            </div>
          </div>
          <div class="flight-list-item-price">
            <div class="price">
              <span class="unit">￥</span>{{ roundData.price | $thousandFormat }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="flight-order-details"
        :class="{'open':showRules}"
      >
        <div class="flight-order-details-head">
          <div
            class="rules-toggle"
            @click="showRulesInfo"
          >
            退改规则
            <en-icon
              name="zhankai"
              :class="{'open':showRules}"
            >
            </en-icon>
          </div>
          <el-tooltip>
            <div class="total-price">
              <div class="title">
                订单总额：
              </div>
              <div class="unit">
                ￥
              </div>
              <div class="price">
                {{ totalPrice | $thousandFormat}}
              </div>
            </div>
            <div slot="content" class="tooltip-content">
              <div class="content-item">
                <div class="price-item">
                  去程：成人票价&emsp;￥{{singleData.price}}&emsp;* {{personalList.length}}
                </div>
                <div class="price-item">
                  机建燃油&emsp;￥{{singleData.ticketInfo.fee + singleData.ticketInfo.tax}}&emsp;* {{personalList.length}}
                </div>
              </div>
              <div class="content-item" v-if="flightType === 2">
                <div class="price-item">
                  返程：成人票价&emsp;￥{{roundData.price}}&emsp;* {{personalList.length}}
                </div>
                <div class="price-item">
                  机建燃油&emsp;￥{{roundData.ticketInfo.fee + roundData.ticketInfo.tax}}&emsp;* {{personalList.length}}
                </div>
              </div>
            </div>
          </el-tooltip>
        </div>
        <div
          class="flight-order-details-list"
          :class="{'show':showRules}"
        >
          <en-tabs
            v-if="flightType === 2"
            v-model="activeRound"
            type="white"
            :list="roundList"
            @change="onTabsChange"
          >
          </en-tabs>
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
          >
            <el-table-column
              label="类型"
              prop="type"
            >
            </el-table-column>
            <el-table-column
              :label="tableHeader[0]"
              prop="beforeFlight"
            >
            </el-table-column>
            <el-table-column
              :label="tableHeader[1]"
              prop="afterFlight"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div
      class="flight-personal-container"
      :class="{'open':showRules}"
    >
      <en-title-card name="乘机人信息">
        <template #left>
          <en-icon
            class="icon-add"
            name="icontianjia-danchuang"
            size="16"
            color="#75AADC"
            @handleClick="contactShow = true"
            v-show="personalList.length < 3 && !rebooking"
          ></en-icon>
        </template>
      </en-title-card>
      <div
        v-en-loading="loading"
        class="flight-personal-list"
      >
        <div
          v-for="(item, index) in personalList"
          :key="item.no"
          class="flight-personal-item"
        >
          <div class="flight-personal-head">
            {{ item.name.substring(0,1) }}
          </div>
          <div class="flight-personal-detail">
            <div class="flight-personal-name">
              {{ item.name }}
            </div>
            <div class="flight-personal-no">
              {{ item.idNumber | hideCardNo(item.idType)}}
            </div>
          </div>
          <en-icon
            name="shanchu-liebiao"
            size="small"
            @click.native="modifyPersonal(index)"
            v-if="!rebooking"
          >
          </en-icon>
        </div>
      </div>
      <div class="flight-button-order">
        <en-button class="flight-button__order" @click="onSubmit">{{rebooking ? "重新预定": "预订"}}</en-button>
      </div>
    </div>
    <en-contact
      v-if="contactShow"
      :dialog-visible.sync="contactShow"
      @getEnContactConfirm="getEnContactConfirm"
    />

    <en-dialog
      :visible="sourceExcessiveDialog"
      width="700px"
      title="资源超标"
      @close="sourceExcessiveDialog = false"
    >
      <div class="">
        <el-input
          class="source-excessive"
          type="textarea"
          placeholder="请输入100字以内超标事由"
          v-model="sourceExcessiveValue"
          resize="none"
          maxlength="100"
          show-word-limit
        >
        </el-input>
      </div>
      <div slot="footer">
        <en-button @click="onSourceExcessive">
          确定
        </en-button>
      </div>
    </en-dialog>

    <en-itinerary
      :dialog-visible.sync="completeDialog"
      :is-new-node="tripId ? false : true"
      @selectTripData="onSelectTrip"
      @selectCreateNewTrip="onNewTrip"
    ></en-itinerary>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { hideCardNo, request } from "en-js";
import dayjs from "dayjs";
import { flightService, tripService } from "@/api/businessTravel";
import enContact from "../components/en-contacts";
import enItinerary from "../components/en-itinerary";

export default {
  name: "FlightOrder",
  components: { enContact, enItinerary },
  data() {
    return {
      tripId: "",
      completeDialog: false,
      loading: null,
      activeRound: "1",
      roundList: [
        {
          id: "1",
          name: "去程"
        }, {
          id: "2",
          name: "返程"
        }
      ],
      personalList: [],
      showRules: false,
      contactShow: false,
      flightType: null,
      singleData: {
        rule: {},
        ticketInfo: {}
      },
      roundData: {},
      flightInfo: {
        fromCity: {
          enCityName: ""
        },
        arrivalCity: {
          enCityName: ""
        }
      },
      sourceExcessiveDialog: false,
      sourceExcessiveValue: ""
    };
  },
  computed: {
    ...mapGetters([
      "userInfo"
    ]),
    ...mapGetters("businessTravel", {
      cacheFlightType: "flightType",
      cacheFlightInfo: "flightInfo",
      getSingleData: "getSingleData",
      getRoundData: "getRoundData"
    }),
    tableHeader() {
      const {
        singleData, roundData, activeRound, flightType
      } = this;
      const r = ["", ""];
      let rule = {};
      if (flightType === 1) {
        rule = singleData.rule;
      } else if (flightType === 2) {
        rule = activeRound === "2" ? roundData.rule : singleData.rule;
      }
      r[0] = rule.messageAgo;
      r[1] = rule.messageAfter;
      return r;
    },
    tableData() {
      const {
        singleData, roundData, activeRound, flightType
      } = this;
      const r = [];
      let rule = {};
      if (flightType === 1) {
        rule = singleData.rule;
      } else if (flightType === 2) {
        rule = activeRound === "2" ? roundData.rule : singleData.rule;
      }
      r.push({
        type: "退票费",
        beforeFlight: rule.returnFeeAgo,
        afterFlight: rule.returnFeeAfter
      });
      r.push({
        type: "同舱改期费",
        beforeFlight: rule.changeFeeAgo,
        afterFlight: rule.changeFeeAfter
      });
      r.push({
        type: "签转条件",
        beforeFlight: rule.isAllowedToSign
      });
      return r;
    },
    totalPrice() {
      const {
        personalList, singleData, roundData, flightType
      } = this;
      if (!singleData.price) {
        return 0;
      }
      let t = 0;
      if (flightType === 1) { // 去程
        t = t + singleData.price + singleData.ticketInfo.fee + singleData.ticketInfo.tax;
      } else if (flightType === 2) {
        t = t + singleData.price + singleData.ticketInfo.fee + singleData.ticketInfo.tax;
        t = t + roundData.price + roundData.ticketInfo.fee + roundData.ticketInfo.tax;
      }
      t = Number(t.toFixed(2));
      const r = Number((personalList.length * t).toFixed(2));
      return r;
    }
  },
  filters: {
    hideCardNo
  },
  methods: {
    /**
     * 跳转行程详情页面
     */
    goTripDetail(tripId) {
      this.$router.push({
        path: "/business-travel/trip/tripDetail",
        query: {
          tripId
        }
      });
    },
    @request()
    async onSelectTrip({ id }) {
      const { excessParams: params } = this;
      await flightService.checkFlightNoByPassgener({
        addTripNodeList: JSON.stringify(params)
      });
      const tripRes = await tripService.saveTripNode({
        addTripNodeList: JSON.stringify(params),
        id,
        tripNo: id
      });
      this.goTripDetail(tripRes.id);
      // this.$router.push({
      //   path: "/business-travel/trip/tripDetail",
      //   query: {
      //     tripId: tripRes.id
      //   }
      // });
    },
    @request()
    async onNewTrip() {
      const { excessParams: params } = this;
      await flightService.checkFlightNoByPassgener({
        addTripNodeList: JSON.stringify(params)
      });
      const res = await tripService.saveTripNode({
        addTripNodeList: JSON.stringify(params),
        id: "",
        tripNo: ""
      });
      this.goTripDetail(res.id);
    },
    @request()
    async onSubmit() {
      const {
        personalList, singleData, roundData, flightType
      } = this;
      let params = [];
      params.push({
        depAirport: singleData.depAirport,
        arrAirport: singleData.arrAirport,
        depTime: singleData.takeoffTime,
        flightNo: singleData.flightNo,
        cabin: singleData.ticketInfo.seatCode
      });
      if (flightType === 2) {
        params.push({
          depAirport: roundData.depAirport,
          arrAirport: roundData.arrAirport,
          depTime: roundData.takeoffTime,
          flightNo: roundData.flightNo,
          cabin: roundData.ticketInfo.seatCode
        });
      }
      // 检查是否还有座位
      const canOrderList = await flightService.getPlaneSeatNum({
        planeList: JSON.stringify(params)
      });

      let errorMsg = "";
      canOrderList.forEach((v) => {
        if (personalList.length > v.lastseats) { // 去程检查票数
          errorMsg += `航班[${v.flightNo}] 起飞时间[${v.takeoffTime}]，`;
        }
      });
      if (errorMsg) {
        errorMsg += "票数不足，请改签";
      }
      if (errorMsg) {
        this.$message.warning(errorMsg);
        return;
      }
      // 校验重复下单问题
      errorMsg = "";
      params = [];
      this.personalList.forEach((p) => {
        params.push(this.getPlaneNode(p, singleData, "000"));
        if (flightType === 2) {
          params.push(this.getPlaneNode(p, roundData, "001"));
        }
      });
      if (params.length === 0) {
        this.$message.warning("请选择乘机人");
        return;
      }
      params.forEach((v) => {
        if (!v.idNumber) {
          errorMsg += `用户[${v.passengerName}] ，`;
        }
      });
      if (errorMsg) {
        errorMsg += "身份证不存在";
      }
      if (errorMsg) {
        this.$message.warning(errorMsg);
        return;
      }

      // 校验航班是否重复预订
      await flightService.checkFlightNoByPassgener({
        addTripNodeList: JSON.stringify(params)
      });

      // 校验航班是否重复预订
      const checkPlaneSupplementRes = await flightService.checkPlaneSupplement({
        tripNodList: JSON.stringify(params)
      });
      this.excessParams = params;
      let excessNum = 0;
      let standardMode = null;
      const excessData = [];
      checkPlaneSupplementRes.forEach((v, i) => {
        if (v.result.isExcess === "000") { // 000 超额，001：没超额
          excessNum++;
          excessData.push(i);
        }
        standardMode = v.result.standardMode; // 超标处理方式是一致的
      });
      // excessNum = 1; // 测试
      // standardMode = "001"; // 测试
      if (excessNum > 0) { // 超额
        if (standardMode === "000") { // 000 不允许提交 001 允许提交
          this.$message.warning("已超标，不允许提交！");
          return;
        }
        this.excessData = excessData;
        this.sourceExcessiveDialog = true;
        return;
      }
      if (this.rebooking) { // 重新预定
        const rebookRes = await tripService.tripAddNewTripNode({
          tripId: this.tripId,
          id: this.tripId,
          tripNodeId: this.tripNodeId,
          tripNodeType: "000",
          addTripNode: JSON.stringify(this.rebookObject)
        });
        this.goTripDetail(rebookRes.id);
        return;
      }
      if (this.tripId) { // 如果有tripId，直接保存
        const tripRes = await tripService.saveTripNode({
          addTripNodeList: JSON.stringify(params),
          id: this.tripId,
          tripNo: ""
        });
        this.goTripDetail(tripRes.id);
        return;
      }
      this.completeDialog = true;
    },
    /**
     * 获取飞机节点
     */
    getPlaneNode(user, plane, backFlag) {
      const { ticketInfo, rule } = plane;
      const node = {
        totalAmount: (ticketInfo.ticketPrice + ticketInfo.fee + ticketInfo.tax), // 必填 总额
        isShare: plane.isshare, // 必填 是否共享 0不共享 1共享
        depCityCh: plane.depCityCh, // 选填 发城市中文名
        depEnCityCode: plane.depCity, // 必填 发地En+城市编码
        arrCityCh: plane.arrCityCh, // 必填 到达城市中文名
        arrEnCityCode: plane.arrCity, // 必填 达地En+城市编码
        arrivalDate: plane.takeoffTime.split(" ")[0], // 必填 行程日期
        takeoffTime: plane.takeoffTime, // 必填 起飞时间
        arriveTime: plane.arriveTime, // 必填 到达时间
        standardFlag: "000", // 必填 000-未超标  001-已超标
        standardMemo: "", // 选填 超标事由
        fromEndorsePlane: "", // 选填 超标事由
        endorseFlag: "000", // 必填 改签标识 000-非改签  001-改签
        refundFlag: "000", // 必填 退票标识 000-没退票  001-已退票
        toEndorsePlane: "", // 选填 改签至哪个航班
        backFlag, // 必填 往返标识 000-去程  001-返程
        status: "000", // 必填 机票节点状态
        approvalPassFlag: "000", // 必填 超标事由

        passengerId: user.userId, // 必填 乘客id
        passengerName: user.name, // 选填 乘机人姓名
        passengerMobile: user.mobile, // 选填 乘机人手机
        idType: user.idType, // 选填 乘机人证件类型 证件类型  000-身份证  001-护照
        idNumber: user.idNumber, // 必填 乘机人证件号

        classType: plane.ticketInfo.classType, // 必填 仓位类型 000-经济舱 001-商务舱 002-头等舱
        ticketNo: "", // 票号
        ticketPrice: ticketInfo.ticketPrice, // 必填 票面价
        buildFee: plane.ticketInfo.fee, // 必填 建费
        fuelFee: plane.ticketInfo.tax, // 必填 燃油费
        refundAmount: "", // 退票手续费
        changeAmount: "", // 改期手续费
        endorseMemo: rule, // 选填

        crrierCh: plane.carrierName, // 必填 航司中文名
        flightNo: plane.flightNo, // 必填 航司中文名
        // actureFlightNo: "", // 选填 共享航班真实航班号
        depAirportCh: plane.depAirportCh, // 必填 出发机场中文名
        arrAirportCh: plane.arrAirportCh, // 必填 到达机场中文名
        fromTerminal: plane.fromTerminal, // 选填 始发航站楼
        arrTerminal: plane.arrTerminal, // 选填 到达航站楼
        stopCityCh: plane.stopCityCh, // 选填 经停城市中文名
        stopArriveTime: plane.stopArriveTime, // 选填 经停到达时间
        stopTakeoffTime: plane.stopTakeoffTime, // 选填 经停起飞时间

        depAirport: plane.depAirport, // 必填 出发机场三字码，如“PEK”
        arrAirport: plane.arrAirport, // 必填 到达机场三字码，如“SHA”
        airCode: plane.carrier, // 必填 航司，如“CA”
        cabin: ticketInfo.seatCode, // 必填 舱位，如“Y”
        salePrice: ticketInfo.salePrice, // 必填 销售价
        settlePrice: ticketInfo.settlePrice, // 必填 结算价
        policyId: ticketInfo.policyId, // 必填 政策ID（政策价格）
        policyPlatType: ticketInfo.policyPlatType, // 必填 政策平台类型.0 国内，1 官网
        policySign: ticketInfo.policySign, // 选填 政策标识.
        policySource: ticketInfo.policySource, // 必填 政策来源.
        tripNodeType: "000", // 必填 资源类型。 000-机票 001-酒店 002补单
        discount: "" //
        // depEnCityCode: TravelSubmit.getEnCityCode(airData.depCityCh),
        // arrEnCityCode: TravelSubmit.getEnCityCode(airData.arrCityCh)
      };
      return node;
    },
    /**
     * 填写超标
     */
    @request()
    async onSourceExcessive() {
      const { excessData, excessParams, sourceExcessiveValue } = this;
      if (!sourceExcessiveValue) {
        this.$message.warning("请输入超标事由！");
        return;
      }
      excessData.forEach((v) => { // 重新添加理由
        excessParams[v].standardFlag = "001";
        excessParams[v].standardMemo = sourceExcessiveValue;
      });
      // 校验航班是否重复预订
      await flightService.checkFlightNoByPassgener({
        addTripNodeList: JSON.stringify(excessParams)
      });
      this.sourceExcessiveDialog = false;
    },
    onTabsChange(val) {
      console.log(val);
    },
    @request(true, "loading")
    async getRebookingData() {
      const res = await tripService.trQueryNewTripNode({
        tripNodeId: this.tripNodeId,
        tripNodeType: "000"
      });
      if (!res) {
        this.$message.warning("请重新选择");
        this.$router.push({
          path: "/business-travel/flight",
          query: {
            tripId: this.tripId
          }
        });
        return;
      }
      this.rebookObject = res;
      this.flightInfo = {
        fromCity: {
          enCityName: res.depCityCh
        },
        arrivalCity: {
          enCityName: res.arrCityCh
        }
      };
      this.flightType = 1;
      // 用来显示
      const singleData = {
        segmentIndex: res.segmentIndex,
        carrier: res.airCode,
        carrierName: res.crrierCh,
        flightNo: res.flightNo,
        isshare: res.isshare,
        depCity: res.depCity,
        depCityCh: res.depCityCh,
        arrCity: res.arrCity,
        arrCityCh: res.arrCityCh,
        depAirport: res.depAirport,
        depAirportCh: res.depAirportCh,
        arrAirport: res.arrAirport,
        arrAirportCh: res.arrAirportCh,
        stopCityCh: res.stopCityCh,
        stopAirport: res.stopAirport,
        stopAirportCh: res.stopAirportCh,
        stopTakeoffTime: res.stopTakeoffTime,
        stopArriveTime: res.stopArriveTime,
        fromTerminal: res.fromTerminal,
        arrTerminal: res.arrTerminal,
        takeoffTime: res.takeoffTime,
        arriveTime: res.arriveTime,
        planeType: res.planeType,
        airPlaneSize: res.airPlaneSize,
        airPlaneSizeCh: res.airPlaneSizeCh,
        price: res.price,
        cabinType: res.cabinType,
        seatNum: res.seatNum,
        // open: "",
        // loading: "",
        // takeoffTimeShow: res.takeoffTime.substr(0, res.takeoffTime.split(" ")[1].lastIndexOf(":")),
        // item.takeoffTimeShow = takeoffTime.substr(0, takeoffTime.lastIndexOf(":"));
        // arriveTimeShow: res.arriveTime.substr(0, res.arriveTime.split(" ")[1].lastIndexOf(":")),
        // takeoffTimeTime: "",
        // arriveTimeTime: "",
        useTime: "",
        ticketInfo: {
          babyFee: res.babyFee,
          babyTax: res.babyTax,
          babyticketPrice: res.babyticketPrice,
          chdFee: res.chdFee,
          chdTax: res.chdTax,
          chdticketPrice: res.chdticketPrice,
          classType: res.classType,
          discount: res.discount,
          fee: res.fee,
          flightNo: res.flightNo,
          itemId: res.itemId,
          policyId: res.policyId,
          policyPlatType: res.policyPlatType,
          policySign: res.policySign,
          policySource: res.policySource,
          priceType: res.priceType,
          salePrice: res.salePrice,
          seatClass: res.seatClass,
          seatCode: res.seatCode,
          seatNum: res.seatNum,
          settlePrice: res.settlePrice,
          tax: res.tax,
          ticketPrice: res.ticketPrice
        },
        rule: JSON.parse(res.endorseMemo)
      };
      const { takeoffTime, arriveTime } = singleData;
      const tot = takeoffTime.split(" ")[1];
      const at = arriveTime.split(" ")[1];
      singleData.takeoffTimeShow = tot.substr(0, tot.lastIndexOf(":"));
      singleData.arriveTimeShow = at.substr(0, at.lastIndexOf(":"));
      const takeoffTimeTime = dayjs(singleData.takeoffTime).toDate().getTime();
      const arriveTimeTime = dayjs(singleData.arriveTime).toDate().getTime();
      let useTime = arriveTimeTime - takeoffTimeTime;
      useTime = Number(useTime / 1000); // 秒
      useTime = Number(useTime / 60); // 分钟
      const s = (useTime % 60);
      const h = Math.floor(useTime / 60);
      singleData.useTime = `${h === 0 ? `${s}m` : `${h}h${s}m`}`;
      this.singleData = singleData;

      this.personalList.unshift({
        userId: res.passengerId,
        name: res.passengerName,
        mobile: res.passengerMobile,
        idType: res.idType,
        idNumber: res.idNumber
      });
    },
    /**
     * @description 获取航班数据信息
     */
    getFlightDataInfo() {
      if (this.rebooking) { // 重新预定
        this.getRebookingData();
      } else {
        // 如果是重新下单，数据将从重新下单获取
        this.flightInfo = this.cacheFlightInfo;
        this.flightType = this.cacheFlightType;
        this.singleData = this.getSingleData;
        if (this.flightType === 2) {
          this.roundData = this.getRoundData;
        }
      }
    },
    /**
       * @description 获取客规详情
       */
    showRulesInfo() {
      this.showRules = !this.showRules;
    },
    /**
       * @description 移除乘机人
       * @param index
       */
    modifyPersonal(index) {
      this.personalList.splice(index, 1);
    },
    /**
       * @description 选择乘机人
       * @param result 选择的人员信息
       */
    getEnContactConfirm(result) {
      const index = this.personalList.findIndex((v) => v.userId === result.id);
      if (index === -1) {
        this.personalList.push(result);
      } else {
        this.$message("不能重复添加!");
        this.personalList[index] = { ...result };
      }
    },
    @request(false)
    async initMe() {
      const { userInfo } = this;
      this.personalList.unshift({
        userId: userInfo.userId,
        name: userInfo.name,
        subName: userInfo.name,
        mobile: userInfo.mobileNo,
        id: userInfo.id,
        idNumber: userInfo.idcardNo
      });
      // const res = await flightService.tripHisContacts();
      // this.contacts = res || [];
      // console.log("res", res);
    },
    /**
       * @description 返回航班列表界面
       */
    backToList() {
      this.$router.go(-1);
    }
  },
  beforeMount() {
    this.rebooking = false;
    this.rebookObject = null;
    this.tripId = this.$route.query?.tripId;
    this.tripNodeId = this.$route.query?.tripNodeId;
    this.rebooking = !!this.$route.query?.rebooking;
    if (!this.rebooking) {
      this.initMe();
    }
    this.getFlightDataInfo();
  }
};
</script>
<style lang="scss">
  @import "../../../assets/css/airline";
</style>
<style lang="scss">
  .tooltip-content {
    .content-item {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;

      .price-item {
        margin-right: 40px;

        &:nth-last-child(1) {
          margin-right: 0;
        }
      }

      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .flight-order /deep/ {
    height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .flight-order-container {
      background-color: #ffffff;
      -webkit-border-radius: 4px 4px 4px;
      -moz-border-radius: 4px 4px 4px;
      border-radius: 4px 4px 4px;
      min-height: 230px;

      .flight-order-header {
        border-bottom: 1px solid #e8ecf2;
        padding: 15px 20px;
        line-height: 20px;
        display: flex;
        justify-content: flex-start;

        .icon-back {
          margin-right: 10px;
          cursor: pointer;
        }

        .icon-qiehuan {
          margin: 1px 5px;
          color: #a9b5c6;
        }

        .label {
          font-size: 14px;
          color: #333333;
          font-weight: bold;
        }
      }

      .flight-order-list {
        width: 100%;

        .flight-order-item {
          display: flex;
          justify-content: space-between;
          width: calc(100% - 40px);
          margin: 0 20px;
          border-bottom: 1px solid #e8ecf2;
          padding: 24px 0 24px 20px;
          position: relative;

          &.isRound {
            padding: 24px 0 24px 70px;
          }

          /* 航班往返时的去返标识 */
          .flight-list-item-status {
            position: absolute;
            width: 40px;
            height: 40px;
            font-size: 13px;
            line-height: 40px;
            color: #ffffff;
            left: 10px;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            background-color: #26c393;
            text-align: center;

            &.is-round {
              background-color: #3e90fe;
            }
          }

          /* 航班基础信息样式 */
          .flight-list-item-basic {
            display: flex;
            justify-content: flex-start;

            .en-icon {
              width: 20px;
              height: 20px;
            }

            .flight-name-info {
              text-align: left;
              margin-left: 6px;

              .flight-list-item-name {
                font-size: 12px;
                color: #333333;
                font-weight: 400;
              }

              .flight-list-item-type {
                font-size: 12px;
                color: #91a1b7;
                margin-top: 12px;
              }
            }
          }

          /* 航班时间信息样式 */
          .flight-list-item-time {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .flight-list-time-item {
              text-align: center;

              .time {
                font-size: 16px;
                line-height: 16px;
                color: #333333;
              }

              .air-port {
                font-size: 12px;
                line-height: 12px;
                color: #636c78;
                margin-top: 6px;
              }
            }

            .flight-list-time-used {
              color: #91a1b7;
              position: relative;

              .flight-list-time-tip {
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translate(-50%, 100%);
                font-size: 12px;
                color: #91A1B7;
                span {
                  color: #3e90fe;
                }
              }
              .time {
                font-size: 12px;
                color: rgba(145, 161, 183, 1);
                width: 120px;
                height: 19px;
                line-height: 19px;
                background: rgba(245, 248, 252, 1);
                border: 1px solid rgba(145, 161, 183, 1);
                border-radius: 10px;
                text-align: center;
              }

              .en-icon {
                width: 61px;
                height: 7px;
                margin: 0 auto;
              }
            }

            .flight-list-time-line {
              height: 0;
              width: 50px;
              border-top: 1px dotted #91A1B7;

              &.left {
                margin: 0 6px 0 16px;
              }

              &.right {
                margin: 0 16px 0 6px;
              }
            }
          }

          /* 航班票价信息及订票和收起按钮 */
          .flight-list-item-price {
            display: flex;
            justify-content: flex-end;
            width: 150px;

            .price {
              color: #f78528;
              font-size: 20px;
              padding: 8px 0 8px 0;

              .unit {
                font-size: 10px;
              }
            }

            .el-button {
              height: 32px;
              width: 72px;
              margin-top: 4px;
            }
          }
        }
      }

      .flight-order-details {
        padding: 20px 20px 20px;
        background-color: #ffffff;
        transition: 0.3s ease-in-out background-color;
        -webkit-border-radius: 0 0 4px 4px;
        -moz-border-radius: 0 0 4px 4px;
        border-radius: 0 0 4px 4px;

        &.open {
          background-color: #fbfbfc;
        }

        .flight-order-details-head {
          display: flex;
          justify-content: space-between;
          line-height: 20px;

          .rules-toggle {
            display: flex;
            justify-content: flex-start;
            color: #3e90fe;
            font-size: 12px;
            line-height: 20px;
            cursor: pointer;

            .en-icon {
              width: 12px;
              height: 12px;
              margin: 4px 10px;
              transition: 0.3s ease-in-out all;

              &.open {
                transform: rotate(-180deg);
              }
            }
          }

          .total-price {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            .title {
              font-size: 12px;
              color: #333333;
              line-height: 1;
            }

            .unit {
              font-size: 12px;
              line-height: 12px;
              color: #f78528;
            }

            .price {
              line-height: 20px;
              font-size: 20px;
              color: #f78528;
              position: relative;
              top: 2px;
            }
          }
        }

        .flight-order-details-list {
          margin-top: 0;
          display: none;

          .en-tabs {
            border: none;
            padding: 0;

            .en-tabs-list {
              background-color: #FBFBFC;
              align-items: flex-end;

              .en-tabs-item {
                width: 140px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                padding: 0;
                border: 1px solid rgba(232, 236, 242, 1);
                border-radius: 5px 5px 0 0;
                margin-right: -1px;
                &.tabs-active {
                  height: 36px;
                  line-height: 36px;
                  background:#f6fafd;
                }
              }
            }
          }
          .el-table{
            margin-top:-1px;
            font-size:12px;
            th>.cell{
              font-weight: normal;
              color: #333;
            }
          }
          &.show {
            margin-top: 20px;
            display: block;
          }
        }
      }
    }

    /* 乘机人信息区域样式 */
    .flight-personal-container {
      margin-top: 10px;
      // height: calc(100% - 301px);
      background-color: #ffffff;
      -webkit-border-radius: 4px 4px 4px;
      -moz-border-radius: 4px 4px 4px;
      border-radius: 4px 4px 4px;
      position: relative;

      .icon-add {
        position: relative;
        cursor: pointer;
        top: 2px;
      }

      &.open {
        // height: calc(100% - 562px);
      }

      .flight-personal-list {
        // height: calc(100% - 115px);
        padding-right: 20px;
        &:after{content:"";display:block;clear:both}
        .flight-personal-item {
          float: left;
          width: 280px;
          height: 80px;
          background-color: #ffffff;
          border: 1px solid #e8ecf2;
          -webkit-border-radius: 4px 4px 4px;
          -moz-border-radius: 4px 4px 4px;
          border-radius: 4px 4px 4px;
          margin: 20px 0 0 20px;
          padding: 20px 20px 20px;
          position: relative;
          transition: 0.3s ease-in-out all;
          display: flex;
          justify-content: flex-start;
          cursor: pointer;

          .flight-personal-head {
            width: 40px;
            height: 40px;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            line-height: 40px;
            font-size: 12px;
            color: #ffffff;
            background-color: #3e90fe;
            text-align: center;
          }

          .flight-personal-detail {
            width: calc(100% - 60px);
            text-align: left;
            margin-left: 20px;

            .flight-personal-name {
              font-size: 12px;
              color: #636c78;
              margin-top: 3px;
              margin-bottom: 9px;
            }

            .flight-personal-no {
              font-size: 12px;
              color: #91a1b7;
            }

            .flight-personal-label {
              line-height: 40px;
              color: #3e90fe;
              font-size: 12px;
            }
          }

          .en-icon {
            position: absolute;
            right: 0;
            top: -5px;
            color: #f76b6b;
            opacity: 0;
            transition: 0.3s ease-in-out opacity;
          }

          &:hover {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

            .en-icon {
              opacity: 1;
            }
          }

          &.add {
            .flight-personal-head {
              background-color: #ffffff;
              color: #3e90fe;
              border: 1px solid #e9e9e9;
            }
          }
        }
      }

      .flight-button-order {
        width: 100%;
        // position: absolute;
        // bottom: 0;
        // right: 0;
        padding: 16px 20px 16px;
        margin-top:20px;
        border-top: 1px solid #e8ecf2;
        text-align: right;

        .flight-button__order {
          width: 72px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          padding: 0;
        }
      }
    }
  }

  .associated-with-stroke {
    display: flex;
    justify-content: space-between;
    padding: 70px 60px 80px 60px;

    .associated-with-stroke-item {
      .icon {
        width: 200px;
        height: 180px;
        background: rgba(245, 249, 253, 1);
        border: 1px solid rgba(232, 236, 242, 1);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .text {
        text-align: center;
        margin-top: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 1;
      }

      &:nth-child(1) {
        .icon {
          background: rgba(245, 249, 253, 1);
        }
      }

      &:nth-child(2) {
        .icon {
          background: rgba(255, 255, 255, 1);
        }
      }

      &:nth-child(3) {
        .icon {
          background: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .source-excessive {
    /deep/ {
      .el-textarea__inner {
        height: 200px;
      }
    }
  }
</style>
