<!--
 * @Author: Louis
 * @Date: 2019-08-14 11:01:59
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-25 11:58:43
 * @Description: 商旅管理-机票预订-列表界面
 -->
<template>
  <div class="flight-list">
    <!-- 过滤条件区域 -->
    <div class="flight-list-filter">
      <div class="flight-list-filter-container" v-if="flightInfo">
        <div class="flight-list-filter-item">
          <el-select
            v-model="flightType"
            @change="changeFlightType"
          >
            <el-option
              v-for="item in roundList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              {{ item.name }}
            </el-option>
          </el-select>
        </div>
        <div
          v-click-outside="blurCity"
          :class="{'active':cityFocus}"
          class="flight-list-filter-item"
        >
          <en-city
            v-model="flightInfo.fromCity"
            @focus="focusCityPicker"
            type="flight"
          >
          </en-city>
          <div class="icon-area">
            <en-icon
              name="chengshiqiehuan"
              size="18px"
            >
            </en-icon>
          </div>
          <en-city
            v-model="flightInfo.arrivalCity"
            @focus="focusCityPicker"
            type="flight"
          >
          </en-city>
        </div>
        <div class="flight-list-filter-item">
          <flight-date
            ref="flightDate"
            :flight-type="flightType"
            :value="[flightInfo.fromDate, flightInfo.arrivalDate]"
            :flight-info="flightInfo"
            @input="onFilghtDateChange"
            width="400px"
          ></flight-date>
        </div>
        <div class="flight-list-filter-item">
          <en-button @click="getFlightListData">
            搜索
          </en-button>
        </div>
      </div>
      <div class="flight-list-filter-container">
        <en-button @click="onDateBefore">前一天</en-button>
        <div class="en-icon-button" @click="onDateChoose">
          <en-icon
            name="riqixuankuang"
            size="12px"
          >
          </en-icon>
        </div>
        <en-button @click="onDateNext">后一天</en-button>
      </div>
    </div>
    <!-- 去程预订 -->
    <div
      v-if="flightType === 2 && singleFlight.carrierName"
      class="flight-list-round"
    >
      <div class="title">
        去
      </div>
      <div class="main-container">
        <div class="flight-basic">
          <div class="icon_airline" :class="['Airlines_' + singleFlight.carrier]"></div>
          <div class="flight-name-name">
            <div class="flight-name">
              {{ singleFlight.carrierName }}
            </div>
            <div class="flight-type">
              {{ singleFlight.flightNo + " 空客" + singleFlight.planeType + " " + "（" +
              singleFlight.airPlaneSizeCh + "）"
              }}
            </div>
          </div>
        </div>
        <div class="flight-time">
          <div class="flight-list-time-item">
            <div class="time">
              {{ singleFlight.takeoffTimeShow }}
            </div>
            <div class="air-port">
              {{ singleFlight.depAirportCh + singleFlight.fromTerminal }}
            </div>
          </div>
          <div class="flight-list-time-line left"></div>
          <div class="flight-list-time-used">
            <div class="time">
              {{ singleFlight.useTime }}
            </div>
            <div class="flight-list-time-tip" v-if="singleFlight.stopAirport">
              <span>停</span> {{singleFlight.stopCityCh}}
            </div>
          </div>
          <div class="flight-list-time-line right"></div>
          <div class="flight-list-time-item">
            <div class="time">
              {{ singleFlight.arriveTimeShow }}
            </div>
            <div class="air-port">
              {{ singleFlight.arrAirportCh + singleFlight.arrTerminal }}
            </div>
          </div>
        </div>
        <div class="flight-price">
          <div class="price">
            <span class="unit">￥</span>{{ singleFlight.price }}
          </div>
          <en-button
            class="reset-btn"
            type="green"
            @click="resetOrder"
          >
            重新订票
          </en-button>
        </div>
      </div>
    </div>
    <!-- 机票列表区域 -->
    <div
      class="flight-list-container"
      :class="{'withSingle': flightType === 2 && singleFlight.carrierName}"
    >
      <!-- 航班信息筛选区域 -->
      <div class="flight-filter">
        <!-- 多选筛选 -->
        <div class="flight-filter-main">
          <en-multi-select
            v-model="flightFilter.time"
            label="起飞时段"
            :list="timeList"
          >
          </en-multi-select>
          <en-multi-select
            v-model="flightFilter.flight"
            label="航空公司"
            :list="flightList"
          >
          </en-multi-select>
          <en-multi-select
            v-model="flightFilter.airPort"
            label="起降机场"
            :list="airPortList"
          >
          </en-multi-select>
          <en-multi-select
            v-model="flightFilter.type"
            label="机型"
            :list="typeList"
          >
          </en-multi-select>
          <!--          <en-multi-select-->
          <!--            v-model="flightFilter.level"-->
          <!--            label="舱位"-->
          <!--            :list="levelList"-->
          <!--          >-->
          <!--          </en-multi-select>-->
        </div>
        <!-- 排序筛选 -->
        <div class="flight-filter-order">
          <div
            :class="{'active': flightFilter.timeOrder > 0}"
            class="flight-filter-order-item"
            @click="changeTimeFilter"
          >
            <div class="label">
              起飞时间
            </div>
            <en-icon
              v-if="flightFilter.timeOrder > 0"
              size="10px"
              :name="flightFilter.timeOrder === 1 ? 'sheng' : 'jiang'"
            >
            </en-icon>
          </div>
          <div
            :class="{'active': flightFilter.priceOrder > 0}"
            class="flight-filter-order-item"
            @click="changePriceFilter"
          >
            <div class="label">
              最低价格
            </div>
            <en-icon
              v-if="flightFilter.priceOrder > 0"
              size="10px"
              :name="flightFilter.priceOrder === 1 ? 'sheng' : 'jiang'"
            >
            </en-icon>
          </div>
        </div>
      </div>
      <!-- 筛选结果列表区域  -->
      <div
        v-if="flightFilter.time.length > 0 || flightFilter.type.length > 0 ||
          flightFilter.level.length > 0 || flightFilter.airPort.length > 0 ||
          flightFilter.flight.length > 0"
        class="flight-result"
      >
        <div class="label">
          选择条件
        </div>
        <div class="filter-result-list">
          <div
            v-for="(item, index) in flightFilter.time"
            :key="index"
            class="filter-result-item"
          >
            <div class="label">
              {{ item.name }}
            </div>
            <en-icon
              name="guanbi-danchuang"
              @handleClick="modifyFilter(item,'time')"
            >
            </en-icon>
          </div>
          <div
            v-for="item in flightFilter.flight"
            :key="item.id"
            class="filter-result-item"
          >
            <div class="label">
              {{ item.name }}
            </div>
            <en-icon
              name="guanbi-danchuang"
              @handleClick="modifyFilter(item,'flight')"
            >
            </en-icon>
          </div>
          <div
            v-for="item in flightFilter.airPort"
            :key="item.id"
            class="filter-result-item"
          >
            <div class="label">
              {{ item.name }}
            </div>
            <en-icon
              name="guanbi-danchuang"
              @handleClick="modifyFilter(item,'airPort')"
            >
            </en-icon>
          </div>
          <div
            v-for="item in flightFilter.type"
            :key="item.id"
            class="filter-result-item"
          >
            <div class="label">
              {{ item.name }}
            </div>
            <en-icon
              name="guanbi-danchuang"
              @handleClick="modifyFilter(item,'type')"
            >
            </en-icon>
          </div>
          <div
            v-for="item in flightFilter.level"
            :key="item.id"
            class="filter-result-item"
          >
            <div class="label">
              {{ item.name }}
            </div>
            <en-icon
              name="guanbi-danchuang"
              @handleClick="modifyFilter(item,'level')"
            >
            </en-icon>
          </div>
          <div
            class="filter-result-empty"
            @click="modifyFilter('isEmpty')"
          >
            清空
          </div>
        </div>
      </div>
      <!-- 航班信息列表 -->
      <div
        v-en-loading="loadingTable"
        v-if="loadingTable || dataListFilter.length > 0"
        class="flight-list-content"
      >
        <div
          v-for="item in dataListFilter"
          :key="item.id"
          class="flight-list-item"
        >
          <div
            class="flight-list-item-main"
            :class="{'active': item.open}"
          >
            <div class="flight-list-item-basic">
              <div class="icon_airline" :class="['Airlines_' + item.carrier]"></div>
              <!--              <en-icon :name="'-'+item.carrier"></en-icon>-->
              <div class="flight-name-info">
                <div class="flight-list-item-name">
                  {{ item.carrierName }}
                </div>
                <div class="flight-list-item-type">
                  {{ item.flightNo + " 空客" + item.planeType + " " + "（" + item.airPlaneSizeCh + "）"
                  }}
                </div>
              </div>
            </div>
            <div class="flight-list-item-time">
              <div class="flight-list-time-item">
                <div class="time">
                  {{ item.takeoffTimeShow }}
                </div>
                <div class="air-port">
                  {{ item.depAirportCh + item.fromTerminal }}
                </div>
              </div>
              <div class="flight-list-time-line left">
              </div>
              <div class="flight-list-time-used">
                <div class="time">
                  {{ item.useTime }}
                </div>
                <div class="flight-list-time-tip" v-if="item.stopAirport">
                  <span>停</span> {{item.stopCityCh}}
                </div>
              </div>
              <div class="flight-list-time-line right">
              </div>
              <div class="flight-list-time-item">
                <div class="time">
                  {{ item.arriveTimeShow }}
                </div>
                <div class="air-port">
                  {{ item.arrAirportCh + item.arrTerminal }}
                </div>
              </div>
            </div>
            <div class="flight-list-item-price">
              <div class="seat-num" v-if="item.seatNum <= 3">
                仅剩{{ item.seatNum }}张
              </div>
              <div class="price">
                <span class="unit">￥</span>{{ item.price }}
              </div>
              <en-button
                :type="item.open ? 'white' : ''"
                @click="showFlightDetails(item)"
              >
                {{ item.open ? "收起" : "订票" }}
              </en-button>
            </div>
            <div class="flight-list-item-arraw" v-if="item.open"></div>
          </div>
          <!-- 舱位信息 -->
          <div
            v-if="item.open"
            v-loading="item.loading"
            class="flight-list-item-details"
          >
            <div v-if="item.flightList.length === 0 && !item.loading" class="flight-list-item-details-tip">当前航班舱位已售罄！</div>
            <div
              v-for="el in item.flightList"
              :key="el.id"
              class="flight-details-item"
            >
              <div class="flight-details-seat-name">
                {{ el.seatClass === 0 ? "经济舱" : el.seatClass=== 1 ? "公务舱" : "头等舱" }}
              </div>
              <div class="flight-details-price-order">
                <div class="flight-details-price">
                  <div class="seat-num" v-if="el.seatNum <= 3">
                    仅剩{{ el.seatNum }}张
                  </div>
                  <span class="unit">￥</span> {{ el.salePrice }}
                </div>
                <en-button class="flight-details-button" @click="orderFlight(item,el)">
                  {{ flightType === 1 ? "订票" : (singleFlight.carrierName ? "选为返程" : "选为去程")}}
                </en-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div style="padding: 15px;">很抱歉，没有找到符合条件的航班哦！</div>
      </div>
    </div>

  </div>
</template>

<script>
import dayjs from "dayjs";
import { cloneDeep, debounce } from "lodash";
import { request } from "en-js";
import { mapGetters, mapActions } from "vuex";
import EnMultiSelect from "@/components/en-multi-select";
import { flightService } from "@/api/businessTravel";
import { FlightDate } from "./components";
import EnCity from "../components/en-city";

export default {
  name: "TravelFlightList",
  components: {
    EnCity,
    EnMultiSelect,
    FlightDate
  },
  data() {
    return {
      // 单程/返程
      roundList: [
        {
          id: 1,
          name: "单程"
        }, {
          id: 2,
          name: "往返"
        }
      ],
      // 航班筛选过滤条件
      flightFilter: {
        time: [],
        flight: [],
        airPort: [],
        type: [],
        level: [],
        timeOrder: 0,
        priceOrder: 0
      },
      // 起飞时段数据源
      timeList: [
        {
          id: 1,
          value: ["00:00:00", "06:00:00"],
          name: "00:00 - 06:00"
        },
        {
          id: 2,
          value: ["06:00:00", "12:00:00"],
          name: "06:00 - 12:00"
        },
        {
          id: 3,
          value: ["12:00:00", "18:00:00"],
          name: "12:00 - 18:00"
        },
        {
          id: 4,
          value: ["18:00:00", "24:00:00"],
          name: "18:00 - 24:00"
        }
      ],
      // 舱位数据源
      levelList: [
        {
          id: 1,
          name: "头等舱"
        },
        {
          id: 2,
          name: "商务舱"
        },
        {
          id: 3,
          name: "经济舱"
        }
      ],
      // 城市选择是否聚焦
      cityFocus: false,
      // 航班数据列表
      dataList: [],
      // 表格是否加载
      loadingTable: null,
      // 单项航班信息
      singleFlight: {},
      flightType: "",
      flightInfo: {},
      loadingList: true
    };
  },
  computed: {
    ...mapGetters("businessTravel", {
      cacheFlightType: "flightType",
      cacheFlightInfo: "flightInfo",
      getSingleData: "getSingleData"
    }),
    dataListFilter() {
      const { dataListShow = [], flightFilter } = this;
      const {
        time, flight, airPort, type, timeOrder, priceOrder
      } = flightFilter;
      let r = cloneDeep(dataListShow);
      if (time.length > 0) {
        r = dataListShow.filter((v) => {
          const today = dayjs(v.takeoffTime).format("YYYY-MM-DD");
          const index = time.findIndex((d) => {
            const st = dayjs(`${today} ${d.value[0]}`).toDate().getTime();
            const et = dayjs(`${today} ${d.value[1]}`).toDate().getTime();
            return v.takeoffTimeTime >= st && v.takeoffTimeTime <= et;
          });
          return index > -1;
        });
      }
      if (flight.length > 0) {
        r = dataListShow.filter((v) => flight.findIndex((d) => d.id === v.carrier) > -1);
      }
      if (airPort.length > 0) {
        r = dataListShow.filter((v) => (
          airPort.findIndex((d) => d.id === v.depAirport) > -1
          || airPort.findIndex((d) => d.id === v.arrAirport) > -1));
      }
      if (type.length > 0) {
        r = dataListShow.filter((v) => type.findIndex((d) => d.id === v.airPlaneSize) > -1);
      }
      if (timeOrder === 1) {
        r = r.sort((a, b) => a.takeoffTimeTime - b.takeoffTimeTime);
      } else if (timeOrder === 2) {
        r = r.sort((a, b) => b.takeoffTimeTime - a.takeoffTimeTime);
      }
      if (priceOrder === 1) {
        r = r.sort((a, b) => a.price - b.price);
      } else if (priceOrder === 2) {
        r = r.sort((a, b) => b.price - a.price);
      }
      return r;
    },
    dataListShow() {
      const { dataList = [] } = this;
      dataList.forEach((item) => {
        const takeoffTime = item.takeoffTime.split(" ")[1];
        item.takeoffTimeShow = takeoffTime.substr(0, takeoffTime.lastIndexOf(":"));
        const arriveTime = item.arriveTime.split(" ")[1];
        item.arriveTimeShow = arriveTime.substr(0, takeoffTime.lastIndexOf(":"));
        item.takeoffTimeTime = dayjs(item.takeoffTime).toDate().getTime();
        item.arriveTimeTime = dayjs(item.arriveTime).toDate().getTime();
        let useTime = item.arriveTimeTime - item.takeoffTimeTime;
        useTime = Number(useTime / 1000); // 秒
        useTime = Number(useTime / 60); // 分钟
        const s = (useTime % 60);
        const h = Math.floor(useTime / 60);
        item.useTime = `${h === 0 ? `${s}m` : `${h}h${s}m`}`;
      });
      return dataList;
    },
    // 航空公司数据源
    flightList() {
      const { dataListShow } = this;
      const r = [];
      dataListShow.forEach((v) => {
        const o = r.find((d) => d.id === v.carrier);
        if (!o) {
          r.push({
            id: v.carrier,
            name: v.carrierName
          });
        }
      });
      return r;
    },
    // 起降机场数据源
    airPortList() {
      const { dataListShow } = this;
      const r = [];
      dataListShow.forEach((v) => {
        let o = r.find((d) => d.id === v.depAirport);
        if (!o) {
          r.push({
            id: v.depAirport,
            name: v.depAirportCh
          });
        }
        o = r.find((d) => d.id === v.arrAirport);
        if (!o) {
          r.push({
            id: v.arrAirport,
            name: v.arrAirportCh
          });
        }
      });
      return r;
    },
    // 机型数据源
    typeList() {
      const { dataListShow } = this;
      const r = [];
      dataListShow.forEach((v) => {
        const o = r.find((d) => d.id === v.airPlaneSize);
        if (!o) {
          r.push({
            id: v.airPlaneSize,
            name: v.airPlaneSizeCh
          });
        }
      });
      return r;
    }
  },
  watch: {
    "flightInfo.fromDate": {
      handler() {
        this.search();
      }
    },
    "flightInfo.arrivalDate": {
      handler() {
        this.search();
      }
    }
  },
  methods: {
    ...mapActions("businessTravel", [
      "setSingleData",
      "setRoundData",
      "setFlightType",
      "setFlightInfo"
    ]),
    /**
     * @description 日期修改
     */
    onFilghtDateChange(val) {
      this.flightInfo.fromDate = val[0];
      this.flightInfo.arrivalDate = val[1];
      this.setFlightInfo(cloneDeep(this.flightInfo));
      this.search();
    },
    /**
     * @description 重置航班数据信息
     */
    resetFlightDataInfo() {
      this.setSingleData({});
      this.setRoundData({});
    },
    /**
     * @description 切换航班类型时，更改终止日期
     */
    changeFlightType() {
      this.setFlightType(this.flightType);
      if (this.flightType === 2) {
        if (!this.flightInfo.arrivalDate) { // 如果返程没值，则开始日期+1
          this.flightInfo.arrivalDate = dayjs(this.flightInfo.fromDate).add(1, "day").format("YYYY-MM-DD");
        }
      }
    },
    /**
     * @description 设置选中
     */
    focusCityPicker(ev) {
      this.cityFocus = !(ev && ev.x > 0);
    },
    /**
     * @description 取消聚焦
     */
    blurCity() {
      this.cityFocus = false;
    },
    /**
     * @description 机票筛选
     */
    _search() {
      this.getFlightListData();
    },
    /**
     * @description 日期往前-1天
     */
    onDateBefore() {
      const { flightInfo } = this;
      // 去程
      if (!this.singleFlight.carrierName) {
        const fromDate = dayjs(flightInfo.fromDate).subtract(1, "day").format("YYYY-MM-DD");
        if (dayjs().isAfter(fromDate, "date")) { // 当前
          this.$message.warning(`去程日期不可早于${dayjs().format("YYYY-MM-DD")}`);
        } else {
          flightInfo.fromDate = fromDate;
        }
        return;
      }
      // 返程
      const startDate = dayjs(flightInfo.fromDate);
      const endDate = dayjs(flightInfo.arrivalDate).subtract(1, "day");
      if (startDate.isAfter(endDate.format("YYYY-MM-DD"), "date")) {
        this.$message.warning(`去程日期不可早于${startDate.format("YYYY-MM-DD")}`);
      } else {
        flightInfo.arrivalDate = endDate.format("YYYY-MM-DD");
      }
    },
    /**
     * @description 日期往后+1天
     */
    onDateNext() {
      const { flightType, flightInfo } = this;
      if (flightType === 1) { // 单独去程
        flightInfo.fromDate = dayjs(flightInfo.fromDate).add(1, "day").format("YYYY-MM-DD");
        return;
      }
      // 去程
      if (!this.singleFlight.carrierName) {
        const startDate = dayjs(flightInfo.fromDate).add(1, "day");
        const endDate = dayjs(flightInfo.arrivalDate);
        if (startDate.isAfter(endDate.format("YYYY-MM-DD"), "date")) {
          this.$message.warning(`去程日期不可晚于${endDate.format("YYYY-MM-DD")}`);
        } else {
          flightInfo.fromDate = startDate.format("YYYY-MM-DD");
        }
        return;
      }
      // 返程
      flightInfo.arrivalDate = dayjs(flightInfo.arrivalDate).add(1, "day").format("YYYY-MM-DD");
    },
    /**
     * @description 选择日期
     */
    onDateChoose() {
      const { flightType } = this;
      const currentType = this.singleFlight.carrierName ? 2 : 1; // 1 去程，2：返程
      this.dateForType = currentType;
      if (flightType === 1) { // 去程
        this.$refs.flightDate.onShowDialog(1, 1);
        return;
      }
      this.$refs.flightDate.onShowDialog(1, currentType);
    },
    /**
     * @description 删除指定过滤条件
     */
    modifyFilter(itemData, filterType) {
      if (itemData === "isEmpty") {
        /* eslint-disable */
        for (const i in this.flightFilter) {
          this.flightFilter[i] = [];
        }
        /* eslint-enable */
      } else {
        this.flightFilter[filterType].forEach((item, index) => {
          if (item.id === itemData.id) {
            this.flightFilter[filterType].splice(index, 1);
          }
        });
      }
    },
    /**
     * 重新定去程票
     */
    resetOrder() {
      this.singleFlight = {};
      this.getFlightListData();
    },
    /**
     * @description 获取航班列表数据
     */
    @request(true, "loadingTable")
    async getFlightListData() {
      this.loadingList = true;
      this.setFlightInfo(cloneDeep(this.flightInfo));
      const { flightType } = this;
      const res = await flightService.getFlightData({
        depAirport: this.flightInfo.fromCity.cityCode,
        arrAirport: this.flightInfo.arrivalCity.cityCode,
        depDate: this.flightInfo.fromDate,
        returnDate: flightType === 1 ? "" : this.flightInfo.arrivalDate,
        voyageType: flightType,
        cabinType: this.flightInfo.level === -1 ? "" : this.flightInfo.level
      });
      const singleFlgihts = res.singleFlgihts || [];
      const returnFlgihts = res.returnFlgihts || [];
      singleFlgihts.forEach((item) => {
        item.open = false;
        item.loading = false;
        item.flightList = [];
      });
      returnFlgihts.forEach((item) => {
        item.open = false;
        item.loading = false;
        item.flightList = [];
      });
      if (!this.singleFlight.carrierName) { // 没有选择去程数据的时候
        this.dataList = singleFlgihts;
      } else {
        this.dataList = returnFlgihts;
      }
      this.loadingList = false;
    },
    /**
     * @description 获取指定航班的机票信息
     * @param dataInfo
     */
    async showFlightDetails(dataInfo) {
      const o = this.dataList.find((v) => v.flightNo === dataInfo.flightNo);
      try {
        o.open = !o.open;
        if (o.open) {
          o.loading = true;
          const res = await flightService.getCabinData({
            depAirport: dataInfo.depAirport,
            arrAirport: dataInfo.arrAirport,
            depDate: dataInfo.takeoffTime,
            flightNo: dataInfo.flightNo,
            cabinType: this.flightInfo.level === -1 ? "" : this.flightInfo.level
          });
          // bug37381
          const levels = this.flightInfo.level;
          res.forEach((item, index) => {
            if (levels === 1) {
              if (item.seatClass === 1 || item.seatClass === 2) {
                  res.splice(index, 1);
              }
            }
            if (levels === 2) {
              if (item.seatClass === 0) {
                  res.splice(index, 1);
              }
            }
          });
          o.flightList = res || [];
        }
      } catch (e) {
        console.error(e);
        this.$message.error("请求出错");
      } finally {
        o.loading = false;
      }
    },
    /**
     * @description 进入订票流程
     * @param flightInfo
     * @param ticketInfo
     */
    @request()
    async orderFlight(flightInfo, ticketInfo) {
      const params = {
        depAirport: flightInfo.depAirport,
        arrAirport: flightInfo.arrAirport,
        carrier: flightInfo.carrier,
        flightNo: flightInfo.flightNo,
        takeoffTime: flightInfo.takeoffTime,
        ticketParPrice: flightInfo.price,
        policySource: ticketInfo.policySource,
        cabin: ticketInfo.seatCode
      };
      const res = await flightService.getTicketRule(params);
      if (!this.singleFlight.carrierName) { // 没有一条数据的时候
        this.singleFlight = { ...flightInfo, ticketInfo, rule: res };
        this.setSingleData(this.singleFlight);
        if (this.flightType === 1) { // 去程
          this.$router.push({
            path: "/business-travel/flight/order",
            query: {
              tripId: this.$route.query.tripId
            }
          });
          return;
        }
        this.getFlightListData();
      } else {
        this.setSingleData(this.singleFlight);
        this.setRoundData({ ...flightInfo, ticketInfo, rule: res });
        // this.$router.push({ path: "/business-travel/flight/order" });
        this.$router.push({
          path: "/business-travel/flight/order",
          query: {
            tripId: this.$route.query.tripId
          }
        });
      }
    },
    /**
     * @description 更改最低价格排序
     */
    changePriceFilter() {
      this.flightFilter.timeOrder = 0;
      if (this.flightFilter.priceOrder === 1) {
        this.flightFilter.priceOrder = 2;
      } else if (this.flightFilter.priceOrder === 2) {
        this.flightFilter.priceOrder = 1;
      } else {
        this.flightFilter.priceOrder = 1;
      }
    },
    /**
     * @description 更改起飞时间排序
     */
    changeTimeFilter() {
      this.flightFilter.priceOrder = 0;
      if (this.flightFilter.timeOrder === 1) {
        this.flightFilter.timeOrder = 2;
      } else if (this.flightFilter.timeOrder === 2) {
        this.flightFilter.timeOrder = 1;
      } else {
        this.flightFilter.timeOrder = 1;
      }
    }
  },
  mounted() {
    this.search = debounce(this._search, 300);
    this.flightType = this.cacheFlightType;
    this.flightInfo = cloneDeep(this.cacheFlightInfo);
    // this.singleFlight = cloneDeep(this.getSingleData);
    this.getFlightListData();
    this.resetFlightDataInfo();
  }
};
</script>

<style lang="scss">
  @import "../../../assets/css/airline";
</style>
<style lang="scss" scoped>
  .flight-list {
    user-select: none;
    background-color: #232c3d;

    .flight-list-filter {
      height: 60px;
      background-color: #ecf1f7;
      border-radius: 4px 4px 4px;
      display: flex;
      justify-content: space-between;
      padding: 14px 20px;

      .flight-list-filter-container {
        display: flex;
        justify-content: flex-start;

        .en-icon-button {
          padding: 9px 30px;
          background-color: #ffffff;
          color: #a9b5c6;
          line-height: 14px;
          cursor: pointer;
        }

        .flight-list-filter-item {
          display: flex;
          justify-content: flex-start;
          margin-right: 12px;
          transition: 0.3s ease-in-out border;

          .el-date-editor /deep/ {
            .el-input__prefix,
            .el-input__suffix {
              .el-input__icon {
                line-height: 32px;
              }
            }
          }

          &:nth-child(2) /deep/ {
            background-color: #ffffff;
            border: 1px solid #dcdfe6;
            line-height: 30px;
            border-radius: 4px 4px 4px;
            transition: 0.2s ease-in-out border;

            .en-city {
              height: 30px;
              border-radius: 4px 4px 4px;

              .el-input__inner {
                height: 30px;
                line-height: 30px;
                border: none;
                border-radius: 4px 4px 4px;
              }
            }

            .icon-area {
              height: 30px;
              padding: 3px 0;
              background-color: #ffffff;

              .en-icon {
                color: #a9b5c6;
              }
            }
          }

          &:nth-child(2) {
            &:hover {
              border-color: #c0c4cc;
            }
          }

          &.active {
            border-color: #3e90fe;

            &:hover {
              border-color: #3e90fe;
            }
          }
        }
      }
    }

    .flight-list-round {
      background-color: #ffffff;
      border-radius: 4px 4px 4px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px;
      height: 90px;
      position: relative;

      .title {
        background-color: #3e90fe;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: #FFFFFF;
        text-align: center;
        font-size: 16px;
        position: absolute;
      }
      .reset-btn {
        width: 72px;
        height: 32px;
        padding: 0;
        line-height: 32px;
        text-align: center;
      }

      .main-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        transition: 0.3s ease-in-out all;
        /* 航班基础信息样式 */
        .flight-basic {
          display: flex;
          justify-content: flex-start;
          position: relative;
          left: 80px;

          .en-icon {
            width: 20px;
            height: 20px;
          }

          .flight-name-name {
            text-align: left;
            margin-left: 6px;

            .flight-name {
              font-size: 12px;
              color: #333333;
              font-weight: 400;
            }

            .flight-type {
              font-size: 12px;
              color: #91a1b7;
              margin-top: 12px;
            }
          }
        }

        /* 航班时间信息样式 */
        .flight-time {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .flight-list-time-item {
            text-align: center;
            .time {
              font-size: 16px;
              line-height: 16px;
              color: #333333;
            }

            .air-port {
              font-size: 12px;
              line-height: 12px;
              color: #636c78;
              margin-top: 6px;
            }
          }

          .flight-list-time-used {
            color: #91a1b7;
            position: relative;

            .time {
              font-size: 12px;
              color:rgba(145,161,183,1);
              width: 120px;
              height: 19px;
              line-height: 19px;
              background:rgba(245,248,252,1);
              border:1px solid rgba(145,161,183,1);
              border-radius:10px;
              text-align: center;
            }
            .flight-list-time-tip {
              position: absolute;
              bottom: -5px;
              left: 50%;
              transform: translate(-50%, 100%);
              font-size: 12px;
              color: #91A1B7;
              span {
                color: #3e90fe;
              }
            }

            .en-icon {
              width: 61px;
              height: 7px;
              margin-bottom: 16px;
            }
          }

          .flight-list-time-line {
            height: 0;
            width: 50px;
            border-top: 1px dotted #91A1B7;
            &.left {
              margin: 0 6px 0 16px;
            }
            &.right {
              margin: 0 16px 0 6px;
            }
          }
        }

        /* 航班票价信息及订票和收起按钮 */
        .flight-price {
          display: flex;
          justify-content: flex-start;

          .price {
            color: #f78528;
            font-size: 20px;
            padding: 8px 14px 8px 0;

            .unit {
              font-size: 10px;
            }
          }

          .en-icon {
            color: #3e90fe;
            margin-top: 10px;
          }
        }

      }
    }

    .flight-no-list-container {
      height: calc(100% - 70px);
      margin-top: 10px;
      background-color: #ffffff;
      -webkit-border-radius: 4px 4px 4px;
      -moz-border-radius: 4px 4px 4px;
      border-radius: 4px 4px 4px;
      transition: 0.3s ease-in-out height;
    }

    .flight-list-container {
      height: calc(100% - 70px);
      margin-top: 10px;
      background-color: #ffffff;
      -webkit-border-radius: 4px 4px 4px;
      -moz-border-radius: 4px 4px 4px;
      border-radius: 4px 4px 4px;
      transition: 0.3s ease-in-out height;

      &.withSingle {
        height: calc(100% - 170px);
      }

      .flight-filter {
        padding: 0 20px;
        border-bottom: 1px solid #e8ecf2;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .flight-filter-main {
          display: flex;
          justify-content: flex-start;
          width: 910px;
          /*padding: 19px 0 18px;*/

          .en-multi-select {
            margin-right: 120px;
          }
        }

        .flight-filter-order {
          display: flex;
          justify-content: flex-start;
          font-size: 12px;
          color: #333333;
          padding: 19px 0 18px;

          .flight-filter-order-item {
            margin-right: 40px;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            padding-right: 12px;
            position: relative;

            .en-icon {
              position: absolute;
              right: 0;
              top: 0;
            }

            &:hover,
            &.active {
              color: #3e90fe;
            }

            &:nth-last-child(1) {
              margin-right: 0;
            }
          }
        }
      }

      .flight-result {
        display: flex;
        justify-content: flex-start;
        padding: 10px 20px 20px;
        border-bottom: 1px solid #e8ecf2;

        .label {
          font-size: 12px;
          line-height: 34px;
          width: 60px;
          text-align: left;
          margin-top: 10px;
        }

        .filter-result-list {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          width: calc(100% - 60px);

          .filter-result-item {
            margin-top: 10px;
            line-height: 12px;
            margin-right: 12px;
            padding: 10px 50px 10px 10px;
            border-radius: 4px 4px 4px;
            border: 1px solid #dce5ec;
            background-color: #f5f8fc;
            color: #636c78;
            position: relative;
            cursor: pointer;
            transition: 0.3s ease-in-out border;

            .label {
              width: inherit;
              line-height: 12px;
              margin-top: 0;
            }

            .en-icon {
              width: 12px;
              height: 12px;
              position: absolute;
              right: 10px;
              top: 10px;
              transition: 0.3s ease-in-out all;

              &:hover {
                color: #ff6000;
              }
            }

            &:hover {
              border-color: #3e90fe;

              .en-icon {
                transform: rotate(270deg);
              }
            }
          }

          .filter-result-empty {
            color: #3e90fe;
            cursor: pointer;
            line-height: 34px;
            margin-top: 10px;
            font-size: 12px;
          }
        }
      }

      .flight-list-content {
        height: calc(100% - 51px);
        overflow-y: auto;

        .flight-list-item {
          width: 100%;

          /* 航班信息主体样式控制 */
          .flight-list-item-main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 90px;
            border-bottom: 1px solid transparent;
            transition: 0.3s ease-in-out box-shadow;
            position: relative;
            padding: 0 40px;
            &:after {
              content: ' ';
              position: absolute;
              left: 20px;
              right: 20px;
              bottom: 0;
              height: 1px;
              background: #E8ECF2;
              transform: translateY(-50%);
            }

            &:hover {
              background: rgba(245,248,252,1);
              border: 1px solid rgba(162,205,247,1);
              box-shadow: 0px 3px 7px 0px rgba(70,148,223,0.14);
            }
            &.active {
              background: rgba(245,248,252,1);
              border: 1px solid rgba(162,205,247,1);
              box-shadow: 0px 3px 7px 0px rgba(70,148,223,0.14);
            }

            /* 航班基础信息样式 */
            .flight-list-item-basic {
              display: flex;
              justify-content: flex-start;

              .en-icon {
                width: 20px;
                height: 20px;
              }

              .flight-name-info {
                text-align: left;
                margin-left: 6px;

                .flight-list-item-name {
                  font-size: 12px;
                  color: #333333;
                  font-weight: 400;
                }

                .flight-list-item-type {
                  font-size: 12px;
                  color: #91a1b7;
                  margin-top: 12px;
                }
              }
            }

            /* 航班时间信息样式 */
            .flight-list-item-time {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .flight-list-time-item {
                text-align: center;
                .time {
                  font-size: 16px;
                  line-height: 16px;
                  color: #333333;
                }

                .air-port {
                  font-size: 12px;
                  line-height: 12px;
                  color: #636c78;
                  margin-top: 6px;
                }
              }

              .flight-list-time-line {
                height: 0;
                width: 50px;
                border-top: 1px dotted #91A1B7;
                &.left {
                  margin: 0 6px 0 16px;
                }
                &.right {
                  margin: 0 16px 0 6px;
                }
              }
              .flight-list-time-used {
                color: #91a1b7;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                text-align: center;
                position: relative;

                .time {
                  font-size: 12px;
                  color:rgba(145,161,183,1);
                  width: 120px;
                  height: 19px;
                  line-height: 19px;
                  background:rgba(245,248,252,1);
                  border:1px solid rgba(145,161,183,1);
                  border-radius:10px;
                }
                .flight-list-time-tip {
                  position: absolute;
                  bottom: -5px;
                  left: 50%;
                  transform: translate(-50%, 100%);
                  font-size: 12px;
                  color: #91A1B7;
                  span {
                    color: #3e90fe;
                  }
                }

                .en-icon {
                  width: 61px;
                  height: 7px;
                  margin: 0 auto;
                }
              }
            }

            /* 航班票价信息及订票和收起按钮 */
            .flight-list-item-price {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              position: relative;

              .seat-num {
                top: 50%;
                left: -20px;
                transform: translate(-100%, -50%);
                position: absolute;
                color: #F54646;
                font-size: 12px;
                font-family:Microsoft YaHei;
                margin-right: 20px;
              }
              .price {
                color: #f78528;
                font-size: 20px;
                padding: 8px 20px 8px 0;

                .unit {
                  font-size: 10px;
                }
              }

              .el-button {
                height: 32px;
                width: 72px;
                margin-top: 4px;
              }
            }
            .flight-list-item-arraw {
              position: absolute;
              width: 0;
              height: 0;
              border: 12px solid;
              border-color: transparent transparent #A2CDF7;
              bottom: -1px;
              right: 64px;
              z-index: 99;
            }
          }

          /* 航班客舱信息样式控制 */
          .flight-list-item-details {
            background-color: #fbfbfc;
            border-bottom: 1px solid #a2cdf7;
            min-height: 60px;

            .flight-list-item-details-tip {
              height: 60px;
              line-height: 60px;
              text-align: center;
              font-size: 12px;
              color: #999;
            }
            .flight-details-button {
              width: 72px;
              padding: 0;
              height: 32px;
              line-height: 32px;
            }
            .flight-details-item {
              display: flex;
              justify-content: space-between;
              padding: 14px 20px 14px 40px;
              width: calc(100% - 40px);
              margin-left: 20px;
              border-bottom: 1px solid #e8ecf2;
              &:last-child {
                border-bottom: 1px solid transparent;
              }
              &:hover {
                background-color: #f5f8fc;
              }

              .flight-details-seat-name {
                font-size: 12px;
                color: #333333;
                font-weight: 400;
                line-height: 32px;
              }

              .flight-details-price-order {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                line-height: 1.12;

                .flight-details-price {
                  color: #f78528;
                  font-size: 20px;
                  margin-right: 20px;
                  position: relative;

                  .seat-num {
                    top: 50%;
                    left: -20px;
                    transform: translate(-100%, -50%);
                    position: absolute;
                    color: #F54646;
                    font-size: 12px;
                    font-family:Microsoft YaHei;
                    margin-right: 20px;
                    line-height: 1;
                  }
                  .unit {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
