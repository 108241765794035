<!--
 * @Author: Louis
 * @Date: 2019-08-12 15:10:18
 * @LastEditors: zhulin
 * @LastEditTime: 2021-07-09 10:55:47
 * @Description: 日历弹窗
 -->
<template>
  <div class="daily-dialog-container" :style="{ width: width }">
    <el-input
      v-show="flightType === 1"
      :value="value[0]"
      type="text"
      placeholder="选择日期"
      prefix-icon="el-icon-date"
      readonly
      @click.native="onShowDialog(1)"
    >
    </el-input>
    <div
      class="daily-dialog-input"
      :class="{ focus: showDialog }"
      v-show="flightType !== 1">
      <el-input
        v-model="dateRange[0]"
        type="text"
        prefix-icon="el-icon-date"
        placeholder="开始日期点"
        @click.native="onShowDialog(2)"
        readonly
      >
      </el-input>
      <div class="split-name">
        <div class="split"></div>
        <div class="content">
          到
        </div>
        <div class="split"></div>
      </div>
      <el-input
        v-model="dateRange[1]"
        type="text"
        placeholder="结束日期点"
        @click.native="onShowDialog(2)"
        readonly
      >
      </el-input>
    </div>
    <en-dialog
      :visible="showDialog"
      title="选择出行日期"
      en-body-scroll
      @close="showDialog=false"
      :width="dialogWidth"
    >
      <div class="daily-dialog-main">
      <div class="daily-dialog-header">
        <div class="panel left-panel">
          <en-icon name="fanhui" size="10px" @click.native="changeMonth(-1)">
          </en-icon>
          <div class="label">
            {{
            dateInfo.left.year +
            "-" +
            (parseInt(dateInfo.left.month, 10) > 9
            ? dateInfo.left.month
            : "0" + parseInt(dateInfo.left.month, 10))
            }}
          </div>
          <en-icon name="fanhui" class="fanhui-right" size="10px" @click.native="changeMonth(1)" v-if="flightType === 1 || showDialogType === 1">
          </en-icon>
        </div>
        <div class="panel right-panel" v-if="flightType === 2 && showDialogType !== 1">
          <div class="label">
            {{
            dateInfo.right.year +
            "-" +
            (parseInt(dateInfo.right.month, 10) > 9
            ? dateInfo.right.month
            : "0" + parseInt(dateInfo.right.month, 10))
            }}
          </div>
          <en-icon name="fanhui" class="fanhui-right" size="10px" @click.native="changeMonth(1)">
          </en-icon>
        </div>
      </div>

      <div class="daily-dialog-content">
        <div class="panel left-panel">
          <div class="week-list">
            <div
              v-for="(item, index) in weekList"
              :key="index"
              :class="{ weekend: index === 0 || index === 6 }"
              class="week-item"
            >
              {{ item }}
            </div>
          </div>
          <div class="daily-list">
            <div
              v-for="(item, index) in leftDates"
              :key="index"
              class="daily-item"
              :class="{
                'not-allow': item.timeStamp < currentTimeStamp,
                empty: !item.timeStamp,
                first: index % 7 === 0,
                last: index % 7 === 6,
                selected: item.isSelect,
                during: item.during,
                weekend: index % 7 === 0 || index % 7 === 6
              }"
              @click="
                item.timeStamp && item.timeStamp >= currentTimeStamp
                  ? selectDateInfo(item)
                  : ''
              "
            >
              <div class="daily-item-date">
                {{ item.date }}
              </div>
              <div
                v-if="showDialogType === 1 && item.price && item.timeStamp >= currentTimeStamp"
                class="daily-item-price"
              >
                {{ "￥" + item.price }}
              </div>
            </div>
          </div>
        </div>
        <div class="panel right-panel" v-if="flightType === 2 && showDialogType !== 1">
          <div class="week-list">
            <div
              v-for="(item, index) in weekList"
              :key="index"
              :class="{ weekend: index === 0 || index === 6 }"
              class="week-item"
            >
              {{ item }}
            </div>
          </div>
          <div class="daily-list">
            <div
              v-for="(item, index) in rightDates"
              :key="index"
              class="daily-item"
              :class="{
                'not-allow': item.timeStamp < currentTimeStamp,
                empty: !item.timeStamp,
                first: index % 7 === 0,
                last: index % 7 === 6,
                selected:
                  item.timeStamp === duringDate.beginDate.timeStamp ||
                  item.timeStamp === duringDate.endDate.timeStamp
                  ,
                during:
                  item.timeStamp > duringDate.beginDate.timeStamp &&
                  item.timeStamp < duringDate.endDate.timeStamp,
                weekend: index % 7 === 0 || index % 7 === 6
              }"
              @click="
                item.timeStamp && item.timeStamp >= currentTimeStamp
                  ? selectDateInfo(item)
                  : ''
              "
            >
              <div class="daily-item-date">
                {{ item.date }}
              </div>
              <div
                v-if="showDialogType === 1 && item.price && item.timeStamp >= currentTimeStamp"
                class="daily-item-price"
              >
                {{ "￥" + item.price }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="daily-dialog-footer">
        <en-icon name="bianhaoguize-shuxing" size="10px" v-if="showDialogType === 1"></en-icon>
        <div class="label" v-if="showDialogType === 1">
          ：当前为{{ showFlightType === 1 ? "去程" : "返程"}}价格
        </div>
      </div>

      </div>
    </en-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { flightService } from "@/api/businessTravel";

export default {
  name: "FlightDate",
  props: {
    flightType: [String, Number],
    value: {
      type: Array,
      default: () => []
    },
    flightInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      prices: {},
      dateInfo: {
        left: {
          year: "2019",
          month: "08",
          list: []
        },
        right: {
          year: "2019",
          month: "09",
          list: []
        }
      },
      currentTimeStamp: "",
      weekList: ["日", "一", "二", "三", "四", "五", "六"],
      duringDate: {
        beginDate: {},
        endDate: {}
      },
      dateRange: [],
      showDialog: false,
      showDialogType: 1, // 1: 单个选择面板, 2: 2个选择面板
      showFlightType: "" // 1:去程, 2:返程
    };
  },
  computed: {
    leftDates() {
      const {
        duringDate, showDialogType, prices, showFlightType
      } = this;
      const { beginDate, endDate } = duringDate;
      const dates = this.dateInfo.left.list || [];
      dates.forEach((v) => {
        v.isSelect = false;
        v.during = false;
        if (showDialogType === 1) {
          if (showFlightType === 1) { // 去程
            v.isSelect = v.timeStamp === beginDate.timeStamp;
          } else {
            v.isSelect = v.timeStamp === endDate.timeStamp;
          }
        } else if (showDialogType === 2) {
          v.isSelect = (v.timeStamp === beginDate.timeStamp) || (v.timeStamp === endDate.timeStamp);
          if (v.timeStamp > beginDate.timeStamp && v.timeStamp < endDate.timeStamp) {
            v.during = true;
          }
        }
        if (prices[v.fullDate]) {
          v.price = prices[v.fullDate];
        }
      });
      return dates;
    },
    rightDates() {
      // const { beginDate, endDate } = duringDate;
      const dates = this.dateInfo.right.list || [];
      return dates;
    },
    width() {
      return this.flightType === 1 ? "200px" : "400px";
    },
    dialogWidth() {
      return this.showDialogType === 1 ? "450px" : "900px";
    }
  },
  watch: {
    value(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.setBasicDateInfo();
        this.getDateList();
      }
    }
  },
  methods: {
    async onShowDialog(type, flightType = 1) {
      this.showDialogType = type;
      this.showFlightType = flightType; // 1, 去程, 2的时候代表返程
      this.setBasicDateInfo();
      this.getDateList();
      this.showDialog = true;
      const res = await flightService.getPriceCalendar({
        depAirport: this.flightInfo.fromCity.cityCode,
        arrAirport: this.flightInfo.arrivalCity.cityCode
      });
      this.prices = res || {};
    },
    /**
     * @description 点击对应日期时触发数据处理
     * @param dateInfo 点击的日期信息
     */
    selectDateInfo(dateInfo) {
      if (this.flightType === 1) { // 当前是单个模式
        this.duringDate.beginDate.timeStamp = dateInfo.timeStamp;
        this.showDialog = false;
        this.dateRange = [];
        this.dateRange.push(dateInfo.fullDate);
        this.dateRange.push(this.value[1]);
        this.$emit("input", this.dateRange);
        return;
      }
      console.log(dateInfo);
      console.log(JSON.parse(JSON.stringify(this.value)));
      if (this.showDialogType === 1) { // 当前是单个选择模式，
        if (this.showFlightType === 1) { // 去程
          const sDate = dayjs(dateInfo.fullDate);
          const eDate = dayjs(this.value[1]);
          const nowDate = dayjs();
          if (nowDate.isAfter(sDate.format("YYYY-MM-DD"), "date")) {
            this.$message.warning(`去程日期不可早于${nowDate.format("YYYY-MM-DD")}`);
            return;
          }
          if (sDate.isAfter(eDate.format("YYYY-MM-DD"), "date")) {
            this.$message.warning(`去程日期不可晚于${eDate.format("YYYY-MM-DD")}`);
            return;
          }
          this.duringDate.beginDate.timeStamp = dateInfo.timeStamp;
          this.dateRange = [];
          this.dateRange.push(dateInfo.fullDate);
          this.dateRange.push(this.value[1]);
        } else {
          const sDate = dayjs(this.value[0]);
          const eDate = dayjs(dateInfo.fullDate);
          if (sDate.isAfter(eDate.format("YYYY-MM-DD"), "date")) {
            this.$message.warning(`返程日期不可早于${sDate.format("YYYY-MM-DD")}`);
            return;
          }
          this.duringDate.endDate.timeStamp = dateInfo.timeStamp;
          this.dateRange = [];
          this.dateRange.push(this.value[0]);
          this.dateRange.push(dateInfo.fullDate);
        }
        this.showDialog = false;
        this.$emit("input", this.dateRange);
        return;
      }
      // 若不存在开始时间信息，将日期信息设置为开始时间信息
      if (!this.duringDate.beginDate.timeStamp) {
        this.duringDate.beginDate.timeStamp = dateInfo.timeStamp;
        this.duringDate.beginDate.dateObj = dateInfo;
        // 若不存在结束时间信息，将日期信息设置为结束时间信息
      } else if (!this.duringDate.endDate.timeStamp) {
        this.duringDate.endDate.timeStamp = dateInfo.timeStamp;
        this.duringDate.endDate.dateObj = dateInfo;
        // 若在有开始和结束时间信息时，点击了日期信息，则视为用户想重新切换区间段
      } else {
        this.duringDate = {
          beginDate: {
            timeStamp: dateInfo.timeStamp,
            dateObj: dateInfo
          },
          endDate: {}
        };
      }
      // 若存在开始和结束时间信息时，需检测用户选择的时间日期先后顺序，并将数据重新数据
      if (
        this.duringDate.beginDate.timeStamp > 0
        && this.duringDate.endDate.timeStamp > 0
      ) {
        const beginInfo = { ...this.duringDate.beginDate.dateObj };
        const endInfo = { ...this.duringDate.endDate.dateObj };
        if (beginInfo.timeStamp > endInfo.timeStamp) {
          const t = this.duringDate.endDate.timeStamp;
          this.duringDate.endDate.timeStamp = this.duringDate.beginDate.timeStamp;
          this.duringDate.endDate.dateObj = beginInfo;
          this.duringDate.beginDate.timeStamp = t;
          this.duringDate.beginDate.dateObj = endInfo;
        }
        this.showDialog = false;
        this.dateRange = [];
        this.dateRange.push(this.duringDate.beginDate.dateObj.fullDate);
        this.dateRange.push(this.duringDate.endDate.dateObj.fullDate);
        // 将用户选择的日期信息，返回给业务调用方
        this.$emit("input", this.dateRange);
      }
    },
    /**
     * @description 设置基础数据信息
     */
    setBasicDateInfo() {
      const beginDate = dayjs(this.value[0]);
      const endDate = dayjs(this.value[1]);
      this.duringDate.beginDate.timeStamp = beginDate.toDate().getTime();
      this.duringDate.endDate.timeStamp = endDate.toDate().getTime();
      this.dateInfo.left.year = beginDate.year();
      this.dateInfo.right.year = endDate.year();
      this.dateInfo.left.month = beginDate.month() + 1;
      this.dateInfo.right.month = endDate.month() + 1;
      if (this.showDialogType === 1 && this.showFlightType === 2) {
        this.dateInfo.left.month = endDate.month() + 1;
      }
      if (
        this.dateInfo.left.month === this.dateInfo.right.month
        && this.dateInfo.left.year === this.dateInfo.right.year
      ) {
        if (this.dateInfo.right.month === 12) {
          this.dateInfo.right.year += 1;
          this.dateInfo.right.month = 1;
        } else {
          this.dateInfo.right.month = parseInt(this.dateInfo.right.month, 10) + 1;
        }
      }
      this.dateRange = [...this.value];
    },
    /**
     * @description 获取日历面板数据信息
     */
    getDateList() {
      let leftLen = 0;
      let rightLen = 0;
      let leftIndex = 0;
      let rightIndex = 0;
      leftLen = new Date(
        this.dateInfo.left.year,
        this.dateInfo.left.month,
        0
      ).getDate();
      rightLen = new Date(
        this.dateInfo.right.year,
        this.dateInfo.right.month,
        0
      ).getDate();
      leftIndex = new Date(
        `${this.dateInfo.left.year}-${this.dateInfo.left.month}-${1}`
      ).getDay();
      rightIndex = new Date(
        `${this.dateInfo.right.year}-${this.dateInfo.right.month}-${1}`
      ).getDay();
      this.dateInfo.left.list = this.getDateIndex(
        this.dateInfo.left.list,
        leftIndex
      );
      this.dateInfo.right.list = this.getDateIndex(
        this.dateInfo.right.list,
        rightIndex
      );
      this.dateInfo.left.list = this.getCurrentDateArr(
        this.dateInfo.left.list,
        this.dateInfo.left.year,
        this.dateInfo.left.month,
        leftLen
      );
      this.dateInfo.right.list = this.getCurrentDateArr(
        this.dateInfo.right.list,
        this.dateInfo.right.year,
        this.dateInfo.right.month,
        rightLen
      );
    },
    /**
     * @description 获取指定月份需要前面空置的日期
     * @param list
     * @param index
     * @return {*}
     */
    getDateIndex(list, index) {
      list = [];
      for (let i = 0; i < index; i++) {
        const obj = {
          date: "",
          timeStamp: "",
          month: "",
          year: "",
          price: ""
        };
        list.push(obj);
      }
      return list;
    },
    /**
     * @description 获取指定月份的所有日期信息
     * @param list
     * @param year
     * @param month
     * @param length
     * @return {*}
     */
    getCurrentDateArr(list, year, month, length) {
      for (let i = 1; i <= length; i++) {
        const obj = {
          date: i,
          timeStamp: new Date(
            `${year}-${month}-${i} 00:00:00`
          ).getTime(),
          year,
          month,
          fullDate: `${year}-${month < 10 ? `0${month}` : month}-${i < 10 ? `0${i}` : i}`
        };
        list.push(obj);
      }
      return list;
    },
    /**
     * @description 切换月份
     * @param num
     */
    changeMonth(num) {
      if (num === -1) {
        if (parseInt(this.dateInfo.left.month, 10) === 1) {
          this.dateInfo.left.month = 12;
          this.dateInfo.left.year = parseInt(this.dateInfo.left.year, 10) - 1;
          this.dateInfo.right.month = parseInt(this.dateInfo.right.month, 10) - 1;
        } else if (parseInt(this.dateInfo.right.month, 10) === 1) {
          this.dateInfo.right.month = 12;
          this.dateInfo.left.month = parseInt(this.dateInfo.left.month, 10) - 1;
          this.dateInfo.right.year = parseInt(this.dateInfo.right.year, 10) - 1;
        } else {
          this.dateInfo.left.month = parseInt(this.dateInfo.left.month, 10) - 1;
          this.dateInfo.right.month = parseInt(this.dateInfo.right.month, 10) - 1;
        }
      } else if (parseInt(this.dateInfo.left.month, 10) === 12) {
        this.dateInfo.left.month = 1;
        this.dateInfo.left.year = parseInt(this.dateInfo.left.year, 10) + 1;
        this.dateInfo.right.month = parseInt(this.dateInfo.right.month, 10) + 1;
      } else if (parseInt(this.dateInfo.right.month, 10) === 12) {
        this.dateInfo.right.month = 1;
        this.dateInfo.right.year = parseInt(this.dateInfo.right.year, 10) + 1;
        this.dateInfo.left.month = parseInt(this.dateInfo.left.month, 10) + 1;
      } else {
        this.dateInfo.left.month = parseInt(this.dateInfo.left.month, 10) + 1;
        this.dateInfo.right.month = parseInt(this.dateInfo.right.month, 10) + 1;
      }
      this.getDateList();
    }
  },
  mounted() {
    /**
     * @description 初始化设置当前日期的时间戳
       */
    this.$nextTick(() => {
      const date = new Date();
      this.currentTimeStamp = new Date(
        `${date.getFullYear()
        }-${
          parseInt(date.getMonth(), 10) + 1
        }-${
          date.getDate()
        } 00:00:00`
      ).getTime();
      // this.setBasicDateInfo();
    });
  }
};
</script>

<style lang="scss" scoped>
  .daily-dialog-container {
    position: relative;
    /deep/ {
      .el-dialog__body {
        padding: 0;
      }
    }

    .daily-dialog-input {
      height: 32px;
      line-height: 30px;
      background-color: #ffffff;
      display: flex;
      justify-content: flex-start;
      -webkit-border-radius: 4px 4px 4px;
      -moz-border-radius: 4px 4px 4px;
      border-radius: 4px 4px 4px;
      border: 1px solid #dcdfe6;
      transition: 0.3s ease-in-out border;
      cursor: pointer;

      &:hover {
        border-color: #c0c4cc;
      }

      &.focus {
        border-color: #3e90fe;
      }

      .split-name {
        height: 30px;
      }

      .el-input /deep/ {
        height: 30px;

        .el-input__inner {
          float: left;
          border: none;
          height: 30px;
        }

        .el-input__icon {
          line-height: 30px;
        }
      }

      .split-name {
        font-size: 12px;
        color: #636c78;
        position: relative;
        display: flex;
        justify-content: flex-start;
        border: none;

        .split {
          height: 1px;
          width: 8px;
          background-color: #e8ecf2;
          margin-top: 15px;
        }

        .content {
          border: 1px solid #e8ecf2;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          border-radius: 10px;
          padding: 0 20px;
          height: 20px;
          font-size: 12px;
          line-height: 20px;
          margin: 5px 0;
          color: #636c78;
        }
      }
    }

    .daily-dialog-main {
      .daily-dialog-title {
        display: flex;
        justify-content: space-between;
        padding: 20px 20px 20px;

        .title {
          font-size: 14px;
          color: #333333;
          font-weight: bold;
        }

        .en-icon {
          cursor: pointer;

          &:hover {
            color: #ff0000;
          }
        }
      }

      /* 头部样式控制 */
      .daily-dialog-header {
        height: 50px;
        background-color: #f6fafd;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #e8ecf2;
        border-bottom: 1px solid #e8ecf2;

        .panel {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          padding: 19px 20px 19px;

          .en-icon {
            cursor: pointer;
            transform: rotate(0);

            &:hover {
              color: #3e90fe;
            }
          }
          .fanhui-right {
            transform: rotate(180deg);
          }

          &.left-panel {
            border-right: 1px solid #e8ecf2;
          }

          .label {
            flex: 1;
            font-size: 12px;
            line-height: 12px;
            color: #333333;
            font-weight: 400;
            text-align: center;
          }
        }
      }

      /* 主体区域样式控制 */
      .daily-dialog-content {
        display: flex;
        justify-content: space-between;

        .panel {
          flex: 1;
          padding-top: 40px;
          padding-bottom: 33px;

          .week-list {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            line-height: 12px;
            padding: 0 40px;

            .week-item {
              color: #333333;

              &.weekend {
                color: #f78528;
              }
            }
          }

          &.left-panel {
            border-right: 1px solid #e8ecf2;
          }

          .daily-list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 24px 14px 0 15px;

            .daily-item {
              width: 60px;
              height: 60px;
              text-align: center;
              cursor: pointer;
              border-radius: 4px 4px 4px;
              transition: 0.3s ease-in-out all;

              .daily-item-date {
                font-size: 14px;
                padding-top: 16px;
                color: #636c78;
              }

              .daily-item-price {
                color: #3e90fe;
                padding-top: 9px;
                font-size: 12px;
                text-indent: -4px;
              }

              &:hover,
              &.selected {
                background-color: #3e90fe;

                .daily-item-date,
                .daily-item-price {
                  color: #ffffff;
                }
              }

              &.during {
                background-color: #f5f7fa;
                border-radius: 0;

                &:hover {
                  .daily-item-date {
                    color: #333333;
                  }

                  .daily-item-price {
                    color: #3e90fe;
                  }

                  &.weekend {
                    .daily-item-date {
                      color: #f78528;
                    }
                  }
                }

                &.first {
                  border-radius: 4px 0 0 4px;
                }

                &.last {
                  border-radius: 0 4px 4px 0;
                }
              }

              &.weekend {
                .daily-item-date {
                  color: #f78528;
                }
              }

              &.not-allow {
                cursor: inherit;
                background-color: transparent;

                .daily-item-date {
                  color: #91a1b7;
                }

                &:hover {
                  .daily-item-date {
                    color: #91a1b7;
                  }
                }
              }
            }
          }
        }
      }

      /* 底部提示区域样式 */
      .daily-dialog-footer {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        padding: 0 20px 20px 20px;
        color: #91a1b7;
        font-size: 12px;
        line-height: 12px;
      }
    }

    .daily-dialog-mask {
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 4;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
</style>
